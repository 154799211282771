import {
  Stepper,
  Step,
  StepLabel,
  styled,
  Radio,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

interface CustomStepperProps {
  activeStep: number;
  steps: number;
  sx?: object;
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#33CD75",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#33CD75",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: "2px",
    height: "6vh",
    marginRight: "21px",
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const CustomStepper = (props: CustomStepperProps) => {
  const { activeStep, steps, sx } = props;
  const theme = useTheme();

  const QontoStepIconRoot = styled("div")<{
    ownerState: { active?: boolean; completed?: boolean };
  }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[400] : "#33CD75",
    display: "flex",
    height: "12px",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      width: "10px",
      left: "-2px",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      left: "-5px",
    },
    alignItems: "center",
    ...(ownerState.active && {
      color: "#33CD75",
    }),
    "& .QontoStepIcon-activeIcon": {
      zIndex: 1,
      marginRight: "-3px",
      padding: "0",
      color: "#33CD75 !important",
      svg: {
        [theme.breakpoints.up("xs")]: {
          width: "18px",
          height: "18px",
        },
        [theme.breakpoints.up("md")]: {
          width: "1em",
          height: "1em",
        },
      },
    },
    "& .QontoStepIcon-completedIcon": {
      zIndex: 1,
      color: "#33CD75 !important",
      marginRight: "-3px",
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("xs")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "25px",
      },
    },
    "& .QontoStepIcon-circle": {
      [theme.breakpoints.up("xs")]: {
        width: "10px",
        height: "10px",
      },
      [theme.breakpoints.up("md")]: {
        width: "16px",
        height: "16px",
      },
      borderRadius: "50%",
      backgroundColor: ownerState.completed
        ? "#33CD75"
        : theme.palette.grey[400],
    },
  }));

  const matches480to540 = useMediaQuery(
    "(min-height: 480px) and (max-height: 540px)"
  );
  const matches479 = useMediaQuery("(max-height: 479px)");

  const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot
        ownerState={{ active, completed }}
        className={className}
      >
        {active && (
          <Radio checked className="QontoStepIcon-activeIcon" sx={{}} />
        )}
        {completed && !active ? (
          <CheckCircle className="QontoStepIcon-completedIcon" />
        ) : (
          !active && <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  };

  return (
    <Stepper
      sx={sx}
      activeStep={activeStep}
      orientation="vertical"
      connector={<CustomConnector />}
    >
      {[...Array(steps)].map((_, index) => (
        <Step
          key={index}
          style={{ position: "relative", pointerEvents: "none" }}
        >
          <StepLabel StepIconComponent={QontoStepIcon} />
          {activeStep === index && (
            <Grid container justifyContent="space-between" alignItems="end">
              <Grid item>
                <Box
                  display={{ xs: "none", md: "block" }}
                  fontFamily="Noto Sans Hebrew"
                  fontSize="10px"
                  fontWeight={600}
                  lineHeight="16px"
                  letterSpacing="0.4000000059604645px"
                  textAlign="center"
                  marginRight="13px"
                  position="absolute"
                  top={{
                    xs: matches480to540 ? "45px" : matches479 ? "40px" : "52px",
                    lg: activeStep + 1 === steps ? "28px" : "66px",
                  }}
                  sx={{ backgroundColor: "#FCF3CF" }}
                >
                  {activeStep + 1}/{steps}
                </Box>
              </Grid>
            </Grid>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
