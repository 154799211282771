import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "Redux/App/hooks";
import {
  GetAllSubmittedQuestion,
  setProgressBarFlag,
  setProgressBarNavigation,
  setQuizQuestionComplete,
  setRemainTime,
  setRemainTimeQuiz,
  setRemainTimeSeries,
  setStudentLevel,
  SubmitQuestionsAns,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import { FormattedMessage } from "react-intl";
import { subtractTimes } from "utils/helperfunctions/getquestiontimevalue";
import { getCookie } from "views/common/cookieUtils";

type Props = {
  isDialogOpen: boolean;
  onClose: Function;
  modal_data?: any;
};

const ModalComponent = ({ isDialogOpen, onClose, modal_data }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user_id = getCookie("id");

  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );

  const remainTime = useAppSelector((state) => state.Questions.remainTime);
  const remainTimeQuiz = useAppSelector((state) => state.Questions.remainTimeQuiz);
  const remainTimeSeries = useAppSelector((state) => state.Questions.remainTimeSeries);

  const { questions }: any = useAppSelector((state) => state.Questions);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClosePopup = () => {
    onClose(false);
  };
  const nextPage = () => {
    const totalQuestions = questions.length;

    dispatch(
      SubmitQuestionsAns({
        user_id: modal_data.user_id,
        container_id: modal_data.container_id,
        question_id: modal_data.question_id,
        collection_id: "",
        answer: "",
        is_skipped_question: 1,
        notes: "",
        time_taken_for_answer: modal_data.time_taken_for_answer,
      })
    ).then((res: any) => {
      const remainingTime = subtractTimes(
        remainTime,
        modal_data.time_taken_for_answer
      );
      const remainingTimeQuiz = subtractTimes(
        remainTimeQuiz,
        modal_data.time_taken_for_answer
      );
      const remainingTimeSeries = subtractTimes(
        remainTimeSeries,
        modal_data.time_taken_for_answer
      );
      dispatch(setRemainTimeQuiz(remainingTimeQuiz))
      dispatch(setRemainTimeSeries(remainingTimeSeries))
      dispatch(setRemainTime(remainingTime));
      dispatch(setStudentLevel(res?.payload?.student_level));

      if (
        window.location.pathname === "/question-pages" &&
        searchParams.get("container_type_id") === "1"
      ) {
        dispatch(updateActiveQuestion(activeQuestion));
        dispatch(
          GetAllSubmittedQuestion({
            user_id: modal_data.user_id,
            container_id: searchParams.get("container_id"),
          })
        );
        dispatch(setQuizQuestionComplete(true));
      }
    });
    if (activeQuestion < totalQuestions - 1) {
      dispatch(updateActiveQuestion(activeQuestion + 1));
      onClose(false);
    } else {
      navigate(
        `/practice-summary?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get(
          "container_id"
        )}&assignment_id=${searchParams.get("assignment_id")}`
      );
    }
  };
  const Series = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        container_id: searchParams.get("container_id"),
      })
    );
    onClose(false);
    dispatch(updateActiveQuestion(0));
    dispatch(setQuizQuestionComplete(true));
    dispatch(setProgressBarNavigation(true));
    if (searchParams.get("container_type_id") === "6") {
      dispatch(setProgressBarFlag(true));
    }
  };
  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClosePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"xs"}
      PaperProps={{
        sx: { borderRadius: "30px" },
      }}
    >
      <Box
        sx={{
          padding: { xs: "25px 15px ", md: "25px" },
          paddingBottom: "35px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Button style={{ justifyContent: "end" }}>
          <Close onClick={handleClosePopup} />
        </Button>
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              sx={{
                color: "#222529",
                textAlign: "center",
                fontWeight: { xs: 600, md: 700 },
                fontSize: { xs: "18px", md: "24px" },
              }}
            >
              {(searchParams.get("container_type_id") != "2" ||
                searchParams.get("container_type_id") != "6") && (
                <FormattedMessage id="no-answer-popup-title" />
              )}
            </Typography>
            <Typography
              sx={{
                color: "#222529",
                textAlign: "center",
                fontWeight: { xs: 600, md: 700 },
                fontSize: { xs: "18px", md: "24px" },
              }}
            >
              {searchParams.get("container_type_id") == "2" ||
              searchParams.get("container_type_id") == "6" ? (
                <FormattedMessage id="modal-seq" />
              ) : (
                <FormattedMessage id="modal-text" />
              )}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "block",
            paddingTop: "20px",
            marginInline: "auto",
          }}
        >
          <Stack direction="row" justifyContent="center" width="100%">
            <Button
              onClick={handleClosePopup}
              autoFocus
              variant="contained"
              style={{
                border: "1px solid #7A49B9",
                marginInline: "5px",
              }}
            >
              <FormattedMessage id="no" />
            </Button>
            <Button
              onClick={
                searchParams.get("container_type_id") == "2" ||
                searchParams.get("container_type_id") == "6"
                  ? Series
                  : nextPage
              }
              style={{
                border: "1px solid #7A49B9",
                marginInline: "5px",
              }}
            >
              <FormattedMessage id="yes" />
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ModalComponent;
