// src/utils/cookieUtils.ts

export const setCookie = (name: string, value: any, hours: number): void => {
    let expires = "";
    if (hours) {
      const date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
      
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };
  
  export const getCookie = (name: string) => {
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookies.split("; ");
    const cookie = cookieArray.find((c) => c.startsWith(`${name}=`));
    return cookie ? cookie.split("=")[1] : undefined;
  };
  
  export const removeAllCookies = (): void => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  };
  
  export const clearCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  };
  