import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface fetchTopics {
  topic_id:string | null;
  course_id:string
}

export const FetchLibraryMeetings = createAsyncThunk(
  "Library/meetings",
  async (data: any) => {
    try {
      const res = await axiosClient.post("get-meetings", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTopics = createAsyncThunk(
  "Library/topics",
  async (data: fetchTopics) => {
    try {
      const res = await axiosClient.post("get-library-topics", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchLeavesScreen = createAsyncThunk(
  "Library/getLeavesScreen",
  async (data: {tag_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-leaves-screen?tag_id=${data.tag_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTeacherProducts = createAsyncThunk(
  "Library/getTeacherProducts",
  async (data: {teacher_id:string | null , course_id: string}) => {
    try {
      const res = await axiosClient.get(
          `get-teacher-products?teacher_id=${data.teacher_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTeacherMeetings = createAsyncThunk(
  "Library/getTeacherMeetings",
  async (data: {teacher_id:string | null, product_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-teacher-meetings?teacher_id=${data.teacher_id}&product_id=${data.product_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTeacherLeavesScreenMeeting = createAsyncThunk(
  "Library/getTeacherLeavesScreenMeeting",
  async (data: {meeting_id:string, course_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-teacher-leaves-screen-for-meeting?meeting_id=${data.meeting_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTeacherLibraryTags = createAsyncThunk(
  "Library/getTeacherLibraryTags",
  async (data: {topic_id:string | null, course_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-teacher-library-tags?topic_id=${data.topic_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTeacherLeavesScreenForTags = createAsyncThunk(
  "Library/getTeacherLeavesScreenForTags",
  async (data: {tags_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-teacher-leaves-screen-for-tags?tags_id=${data.tags_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchStudentLeavesScreenForMeeting = createAsyncThunk(
  "Library/getStudentLeavesScreenForMeeting",
  async (data: {meeting_id:string, course_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-student-leaves-screen-for-meeting?meeting_id=${data.meeting_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchSearchLibraryDropDownList = createAsyncThunk(
  "Library/searchLibraryDropDownList",
  async (data: {course_id:string}) => {
    try {
      const res = await axiosClient.get(
        `get-library-tags?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const FetchSearchLibrary = createAsyncThunk(
  "Library/fetchSearchLibrary",
  async (data: {user_id:string | null, tag_id:number | null}) => {
    try {
      const res = await axiosClient.get(
        `search-library?user_id=${data.user_id}&tag_id=${data.tag_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  isLoading: boolean;
  meetings: any[];
  topics: any[];
  libraryBreadcrumbs: any[];
  error: any;
  leaveScreen: any[];
  teacherProducts: any[];
  teacherMeetings: any[];
  teacherLeavesScreenMeeting: any[];
  teacherLibraryTags: any[];
  teacherLeavesScreenForTags: any[];
  studentLeavesScreenForMeeting: any[];
  subjectTabFlag: boolean;
  showParentsDataMeetings: boolean;
  isBackFromAtomic: boolean;
  meetingsName: string;
  teacherLibraryFirstPageUrl: string;
  searchLibraryDropDownListArr: [
    {
      id: number;
      name: string;
      icon: string;
    }
  ];
  fetchSearchLibrary: any[];
}

const initialState: initialStage = {
  isLoading: false,
  meetings: [],
  topics: [],
  libraryBreadcrumbs: [],
  error: null,
  leaveScreen: [],
  teacherProducts: [],
  teacherMeetings: [],
  teacherLeavesScreenMeeting: [],
  teacherLibraryTags: [],
  teacherLeavesScreenForTags: [],
  studentLeavesScreenForMeeting: [],
  subjectTabFlag: false,
  showParentsDataMeetings: false,
  isBackFromAtomic: false,
  meetingsName: "",
  teacherLibraryFirstPageUrl: "",
  searchLibraryDropDownListArr: [
    {
      id: 0,
      name: "",
      icon: "",
    },
  ],
  fetchSearchLibrary: [],
};

const MeetingsSubjects = createSlice({
  name: "library-meetings-subjects",
  initialState: {
    ...initialState,
  },
  reducers: {
    setSubjectTabFlag: (state, action) => {
      return { ...state, subjectTabFlag: action.payload };
    },
    setShowParentsDataMeetings: (state, action) => {
      return { ...state, showParentsDataMeetings: action.payload };
    },
    isBackFromAtomicData: (state, action) => {
      return { ...state, isBackFromAtomic: action.payload };
    },
    setMeetingsName: (state, action) => {
      return { ...state, meetingsName: action.payload };
    },
 
  },
  extraReducers(builder) {
    builder
      .addCase(FetchLibraryMeetings.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchLibraryMeetings.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          meetings: action.payload.data,
        };
      })
      .addCase(FetchLibraryMeetings.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchTopics.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTopics.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          topics: action.payload.data.data,
          libraryBreadcrumbs: action?.payload?.bradcrum,
        };
      })
      .addCase(FetchTopics.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchLeavesScreen.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchLeavesScreen.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          leaveScreen: action.payload.data,
          libraryBreadcrumbs: action?.payload?.bradcrum,
        };
      })
      .addCase(FetchLeavesScreen.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchTeacherProducts.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTeacherProducts.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherProducts: action.payload.data,
        };
      })
      .addCase(FetchTeacherProducts.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchTeacherMeetings.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTeacherMeetings.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherMeetings: action.payload.data,
        };
      })
      .addCase(FetchTeacherMeetings.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchTeacherLeavesScreenMeeting.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTeacherLeavesScreenMeeting.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherLeavesScreenMeeting: action.payload.data,
        };
      })
      .addCase(FetchTeacherLeavesScreenMeeting.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchTeacherLibraryTags.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTeacherLibraryTags.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherLibraryTags: action.payload.data.data,
          libraryBreadcrumbs: action?.payload?.bradcrum,
        };
      })
      .addCase(FetchTeacherLibraryTags.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchTeacherLeavesScreenForTags.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTeacherLeavesScreenForTags.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherLeavesScreenForTags: action.payload.data,
          libraryBreadcrumbs: action?.payload?.bradcrum,
        };
      })
      .addCase(FetchTeacherLeavesScreenForTags.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchStudentLeavesScreenForMeeting.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(
        FetchStudentLeavesScreenForMeeting.fulfilled,
        (state, action) => {
          return {
            ...state,
            isLoading: false,
            studentLeavesScreenForMeeting: action.payload.data,
          };
        }
      )
      .addCase(FetchStudentLeavesScreenForMeeting.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchSearchLibraryDropDownList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchSearchLibraryDropDownList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          searchLibraryDropDownListArr: action.payload.tags,
        };
      })
      .addCase(FetchSearchLibraryDropDownList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      })
      .addCase(FetchSearchLibrary.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchSearchLibrary.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          fetchSearchLibrary: action.payload,
        };
      })
      .addCase(FetchSearchLibrary.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      });
  },
});
export const {
  setShowParentsDataMeetings,
  setSubjectTabFlag,
  setMeetingsName,
  isBackFromAtomicData,
} = MeetingsSubjects.actions;
export default MeetingsSubjects.reducer;
