import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";
import { StudentExamList } from "types/studentExamList";

interface getNewPracticeOnlineData {
  student_id: string | null;
  onlineExamType: string;
  course_id: string;
}
interface getPerPracticeOnlineAllData {
  student_id: string | null;
  per_page: number;
  page: number;
  subject?: string;
  level?: string;
  success_rate?: string;
}
interface Tag {
  id: number;
  name: string;
}

interface StudentTagPayload {
  student_id: string | undefined;
  selectedTags: Tag[];
  fundamental_principal?:number
}
interface getStudentExamList {
  type: string;
  student_id: string | null;
  course_id: string;
}
interface getstudentexamchapter {
  student_id: string | undefined;
  course_id: string | undefined;
}
interface saveOnlinePracticeInfo {
  student_id: string | null;
  selectedTags: any[];
  amount_of_question: number;
  choose_questions: string | undefined;
  time_per_question: number;
  fundamental_principal: number | string | null;
  onlineExamType: string;
  all_questions: number;
  from_not_solved: number;
  course_id: string;
  subject_list: any[];
}
interface getPracticeInfoAll {
  student_id: string | null | any[];
  course_id: string;
  onlineExamType: string;
}
interface ExamData {
  examId: number;
  containerId: number;
  examName: string;
  chapterName: string;
}

interface ExamPayload {
  exam_name: string ;
  course_id: string | undefined;
  student_id: string |undefined;
  exam_data: ExamData[];
}

export const GetNewPracticeOnlineData = createAsyncThunk(
  "onlinePracticeExamSlice/getNewPracticeOnlineData",
  async (data: getNewPracticeOnlineData) => {
    try {
      const res = await axiosClient.get(
        `get-practice-online?student_id=${data.student_id}&onlineExamType=${data.onlineExamType}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetNewPracticeOnlineDataTeacher = createAsyncThunk(
  "onlinePracticeExamSlice/getNewPracticeOnlineDataTeacher",
  async (data: { course_id: string | null }) => {
    try {
      const res = await axiosClient.get(
        `get-practice-online-assignment?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveOnlinePracticeInfo = createAsyncThunk(
  "onlinePracticeExamSlice/saveOnlinePracticeInfo",
  async (data: saveOnlinePracticeInfo) => {
    try {
      const res = await axiosClient.post(`save-online-practice-info`, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetPerPracticeOnlineAllData = createAsyncThunk(
  "onlinePracticeExamSlice/getPerPracticeOnlineAllData",
  async (data: getPerPracticeOnlineAllData) => {
    try {
      let url = `get-practice-online-list?student_id=${data.student_id}&per_page=${data.per_page}&page=${data.page}`;
      if (
        data.subject !== undefined &&
        data.level !== undefined &&
        data.success_rate !== undefined
      ) {
        url += `&subject=${data.subject}&level=${data.level}&success_rate=${data.success_rate}`;
      }

      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetPracticeInfoAll = createAsyncThunk(
  "onlinePracticeExamSlice/practiceInfoAll",
  async (data: getPracticeInfoAll) => {
    try {
      const res = await axiosClient.post(
        `get-all-practice-online-assignment`,
        data
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetUnresolvedQuestionCount = createAsyncThunk(
  "onlinePracticeExamSlice/GetUnresolvedQuestionCount",
  async (data: StudentTagPayload) => {
    try {
      const res = await axiosClient.post(`get-unresolve-question-count`, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const SaveExamChapters = createAsyncThunk(
  "onlinePracticeExamSlice/SaveExamChapters",
  async (data: ExamPayload) => {
    try {
      const res = await axiosClient.post(`save-exam-chapters`, data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetStudentExamList = createAsyncThunk(
  "onlinePracticeExamSlice/getStudentExamList",
  async (data: getStudentExamList) => {
    try {
      const res = await axiosClient.get(
        `get-exam-list-student?type=${data.type}&student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      }
    }
  }
);
export const GetStudentExamChapter = createAsyncThunk(
  "onlinePracticeExamSlice/getStudentExamChapter",
  async (data: getstudentexamchapter) => {
    try {
      const res = await axiosClient.get(
        `get-exam-chapters?student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      }
    }
  }
);
interface initialStage {
  isLoading: boolean;
  error: any;
  getExamListStudent: StudentExamList[];
  first_container_id:number,
  exam_details:any[];
  getExamChapterStudent:any[];
  malo_test:any[];
  questionData: boolean;
  pending_count: number;
  all_questions_with_principal:number;
  getNewPracticeTabExamData: {
    subject: any[];
    fundamental_principal: any[];
    all_questions: number;
    from_not_solved: number;
  };
  saveOnlinePracticeInfo: {
    title: string;
    display_name: string;
    container_type_id: number;
    container_duration: string;
    is_active: number;
    created_by: string;
    is_random: number;
    id: number | undefined;
  };
  getPerPracticeOnlineData: {
    data: {
      practice_id: number;
      date: string;
      total_questions: number;
      avg_time: string;
      total_time: string;
      success_percentage: string;
      subject: string;
    }[];
    current_page: string;
    per_page: string;
    total: number;
    to: number;
  };
  sidBarData: any[];
  type: string;
}

const initialState: initialStage = {
  isLoading: false,
  error: null,
  pending_count: -1,
  all_questions_with_principal:-1,
  getExamListStudent: [],
  first_container_id:0,
  exam_details:[],
  getExamChapterStudent:[],
  malo_test:[],
  questionData: false,
  getNewPracticeTabExamData: {
    subject: [],
    fundamental_principal: [],
    from_not_solved: 0,
    all_questions: 0,
  },
  saveOnlinePracticeInfo: {
    title: "",
    display_name: "",
    container_type_id: 0,
    container_duration: "",
    is_active: 0,
    created_by: "",
    is_random: 0,
    id: undefined,
  },

  getPerPracticeOnlineData: {
    data: [
      {
        practice_id: 0,
        date: "",
        total_questions: 0,
        avg_time: "00:00",
        total_time: "00:00",
        success_percentage: "0%",
        subject: "",
      },
    ],
    current_page: "",
    per_page: "",
    total: 0,
    to: 0,
  },
  sidBarData: [],
  type: "initial",
};

const OnlinePracticeExamSlice = createSlice({
  name: "onlinePracticeExamSlice",
  initialState,
  reducers: {
    updateSelectedChapter: (state, action) => {
      return { ...state, selectedChapter: action.payload };
    },
    updateQuestionData: (state, action) => {
      return { ...state, questionData: action.payload };
    },
    updatePendingCount: (state, action) => {
      return { ...state, pending_count: action.payload };
    },
    updateQuePrincipal: (state, action) => {
      return { ...state, all_questions_with_principal: action.payload };
    },
    updateSidebarData: (state, action) => {
      return { ...state, sidBarData: action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetNewPracticeOnlineData.pending, (state, action) => {
        return {
          ...state,
          getNewPracticeTabExamData: {
            all_questions: 0,
            from_not_solved: 0,
            subject: [],
            fundamental_principal: [],
          },
          isLoading: true,
        };
      })
      .addCase(GetNewPracticeOnlineData.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getNewPracticeTabExamData: action.payload.data,
        };
      })
      .addCase(GetNewPracticeOnlineData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetNewPracticeOnlineDataTeacher.pending, (state, action) => {
        return {
          ...state,
          getNewPracticeTabExamData: {
            all_questions: 0,
            from_not_solved: 0,
            subject: [],
            fundamental_principal: [],
          },
          isLoading: true,
        };
      })
      .addCase(GetNewPracticeOnlineDataTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getNewPracticeTabExamData: action.payload.data,
        };
      })
      .addCase(GetNewPracticeOnlineDataTeacher.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })

      .addCase(SaveOnlinePracticeInfo.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SaveOnlinePracticeInfo.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          saveOnlinePracticeInfo: action.payload.data,
          sidBarData: action.payload.sidebar_data,
        };
      })
      .addCase(SaveOnlinePracticeInfo.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })
      .addCase(GetPracticeInfoAll.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetPracticeInfoAll.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getNewPracticeTabExamData: action.payload.data,
        };
      })
      .addCase(GetPracticeInfoAll.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })
      .addCase(GetUnresolvedQuestionCount.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetUnresolvedQuestionCount.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          pending_count: action.payload.data.unresolve_questions,
          all_questions_with_principal: action.payload.data.all_questions_with_principal,
        };
      })
      .addCase(GetUnresolvedQuestionCount.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })
      .addCase(SaveExamChapters.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SaveExamChapters.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          exam_details: action.payload.data,
          first_container_id:action.payload.cotnainer_id
        };
      })
      .addCase(SaveExamChapters.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })

      .addCase(GetPerPracticeOnlineAllData.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetPerPracticeOnlineAllData.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getPerPracticeOnlineData: {
            data: action.payload.data.data,
            current_page: action.payload.data.current_page,
            per_page: action.payload.data.per_page,
            total: action.payload.data.total,
            to: action.payload.data.to,
          },
        };
      })

      .addCase(GetPerPracticeOnlineAllData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })

      .addCase(GetStudentExamChapter.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetStudentExamChapter.fulfilled, (state, action) => {
        return {
          ...state,
          getExamChapterStudent: action.payload.data.kidum_test,
          malo_test:action.payload.data.malo_test,
          isLoading: false,
        };
      })
      .addCase(GetStudentExamChapter.rejected, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetStudentExamList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetStudentExamList.fulfilled, (state, action) => {
        return {
          ...state,
          getExamListStudent: action.payload.data,
          isLoading: false,
        };
      })
      .addCase(GetStudentExamList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      });
      
  },
});

export const {
  updateSelectedChapter,
  updateQuestionData,
  updatePendingCount,
  updateSidebarData,
  updateQuePrincipal
} = OnlinePracticeExamSlice.actions;

export default OnlinePracticeExamSlice.reducer;
