import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import Header from "views/student/Header";
import Footer from "views/common/Footer";
import LoadingFallback from "views/common/LoadingFallback";

// Lazy load components
const TeacherHome = lazy(() => import("../views/Teachers/TeacherHome"));
const NotificationsTeacher = lazy(
  () => import("views/Notifications/NotificationsTeacher")
);

const ClassManagement = lazy(
  () => import("../views/class-management/ClassManagement")
);
const StudentManagement = lazy(
  () => import("../views/student-management/StudentManagement")
);
const StatusPresence = lazy(
  () => import("../views/student-management/StatusPresence")
);
const Tests = lazy(() => import("../views/student-management/Tests"));
const Assignments = lazy(
  () => import("../views/student-management/Assignments")
);
const WorkGroups = lazy(() => import("../views/student-management/WorkGroups"));
const StudentMeeting = lazy(
  () => import("../views/student-management/StudentMeeting")
);
const RegisterPage = lazy(() => import("../views/on-bording/RegisterPage"));
const NoPageFound = lazy(() => import("views/common/NoPageFound"));
const ClassMapPage = lazy(() => import("views/class-map/ClassMapPage"));
const Attendance = lazy(() => import("views/student-management/Attendance"));
const AttendanceStudent = lazy(
  () => import("views/student-management/AttendanceStudent")
);
const StudentManagementDashboard = lazy(
  () => import("views/student-management/StudentManagementDashboard")
);
const StudentProfile = lazy(
  () => import("views/student-management/StudentProfile")
);
const ClassManagementExamStatus = lazy(
  () => import("views/class-management/ClassManagementExamstatus")
);
const ClassManagementExamStatusTestWise = lazy(
  () => import("views/class-management/ClassManagementExamstatusTestWise")
);
const ClassManagementExamStatusExaminerWise = lazy(
  () => import("views/class-management/ClassManagementExamstatusExaminerWise")
);
const SessionManagement = lazy(
  () => import("views/student-management/SessionManageMent")
);
const ClassroomManagementAssignmentsStatus = lazy(
  () => import("views/class-management/ClassroomManagementAssigmentsStatus")
);
const AddAssignment = lazy(
  () => import("views/student-management/AddAssignment")
);
const EssayListClassManagement = lazy(
  () => import("views/class-management/EssayModule/EssayListClassManagment")
);
const EssayPreviewClassManagement = lazy(
  () => import("views/class-management/EssayModule/EssayPreviewClassManagment")
);
const EssayEditClassManagement = lazy(
  () => import("views/class-management/EssayModule/EassyEditClassManagment")
);
const StudentManagementTeacherLesson = lazy(
  () => import("views/student-management/StudentManagementTeacherLesson")
);
const StudentManagementPractice = lazy(
  () => import("views/student-management/StudentManagementPractice")
);
const SessionManagementCourse = lazy(
  () => import("views/student-management/SessionManagementCourse")
);
const ClassManagementEssayList = lazy(
  () => import("views/class-management/ClassManagmentEssayList")
);
const EssayStudentManagementTS = lazy(
  () => import("views/student-management/EssayModule/EassayStudentManagmentTS")
);
const EssayClassManagement = lazy(
  () => import("views/class-management/EssayModule/EssayClassManagment")
);
const TeacherProfile = lazy(() => import("views/Teachers/TeacherProfile"));
const ClassroomManagementWorkGroups = lazy(
  () => import("views/class-management/ClassroomManagementWorkGroups")
);
const DistanceFromTheTargetPoint = lazy(
  () => import("views/class-management/DistanceFromTheTargetPoint")
);

const TeacherRoutes = {
  path: "/",
  element: <Outlet />,
  children: [
    {
      path: "*",
      element: <NoPageFound />,
    },
    {
      path: "/on-boarding",
      element: <RegisterPage />,
    },
    {
      path: "/teacher-home",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Teacher Home">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TeacherHome />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/notifications-teacher",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Notification">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <NotificationsTeacher />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/exam-status",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassManagementExamStatus />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/exam-status/:examId",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassManagementExamStatusTestWise />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/distancePoint",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <DistanceFromTheTargetPoint />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/exam-status-examiner/:examId",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassManagementExamStatusExaminerWise />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/lesson",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentManagementTeacherLesson />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentManagementDashboard />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/student-exam/result",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/presence",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StatusPresence />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/meeting",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentMeeting />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/work-groups",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <WorkGroups />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/assignments",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Assignments />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/tests",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Tests />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/class-map",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassMapPage />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-profile",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentProfile />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/session-management",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <SessionManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/session-management-course-wise",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <SessionManagementCourse />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/class-management/essay-board",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EssayClassManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/essay-list",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EssayStudentManagementTS />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/class-management/classroom-work-groups",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassroomManagementWorkGroups />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/essay/preview-connection/:previewConnection",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EssayPreviewClassManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/essay/edit-connection/:editConnection",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EssayEditClassManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/essay/search-connection/:productId",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <EssayListClassManagement />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/class-management/essay-list",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassManagementEssayList />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/session-management/add-assignment",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <AddAssignment />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/home/session-management/add-assignment",
    //   element: (
    //     <ProtectedRoute user_type={"Teacher"}>
    //       <Header />
    //       <Suspense fallback={<LoadingFallback />}>
    //         <ClassMapPage />
    //       </Suspense>
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: "/home/student-management/attendance",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Attendance />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/attendance-student",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <AttendanceStudent />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/student-management/practice",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Student Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <StudentManagementPractice />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/assignments-status",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <ClassroomManagementAssignmentsStatus />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/classroom-management/assignments-status/add-assignment",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <AddAssignment />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/session-management/course-wise/add-assignment",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Classroom Management">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <AddAssignment />
          </Suspense>
        </ProtectedRoute>
      ),
    },
    {
      path: "/home/teacher-profile",
      element: (
        <ProtectedRoute user_type={"Teacher"} title="Teacher Profile">
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <TeacherProfile />
          </Suspense>
          <Footer />
        </ProtectedRoute>
      ),
    },
  ],
};

export default TeacherRoutes;
