import React, { useEffect, useState } from "react";
import {
  IconButton,
  Modal,
  Tab,
  Tabs,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setProgressBarFlag,
  setQuizQuestionComplete,
  SubmitQuestionsAns,
  updateActiveQuestion,
  setQuizColorChange,
  setProgressBarColor,
  setTimerFlag,
  setTotalExamTime,
  setStartIndex,
  setShowResultModeQuiz,
  setShowResultQuiz,
  setShowModalQuiz,
  setStudentLevel,
  GetOnlineExamChapter,
  setProgressBarNavigation,
  setRemainTime,
  setPreviewName,
  setResearchTab,
  setNumericBar,
  setIsTimer,
} from "Redux/feature/Questions";
import OpenTextBox from "../QuestionTypes/OpenTextBox";
import QuestionSideBar from "../Sidebar/QuestionSideBar";
import TextImageWithQuestion from "../QuestionTypes/TextImageWithQuestion";
import NumericBulletSingleChoice from "../QuestionTypes/NumericBulletSingleChoice";
import LongText from "../QuestionTypes/LongText";
import ImageQuestion from "../QuestionTypes/ImageQuestion";
import EnglishLayout from "../QuestionTypes/EnglishLayout";
import Quantitative from "../QuestionTypes/Quantative";
import VerbatimText from "../QuestionTypes/VerbatimText";
import { FormattedMessage } from "react-intl";
import { Typography, Button, Box, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as EditIconCollaps } from "../../../assets/images/editIconCollaps.svg";
import { ReactComponent as EditIconCollapsModel } from "../../../assets/images/EditIconCollapsModel.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/ArrowRight.svg";
import AssignmentDialog from "views/common/AssignmentDialog";
import {
  CompleteContainerAtomic,
  GetNextAssignment,
  setActiveIndex,
  setAssignmentData,
  setIconSidebar,
  setOpenAssignmentSuccess,
  setOpenSessionSuccess,
  setSideBar,
} from "Redux/feature/StudentHome";
import SessionDialog from "views/common/SessionDialog";
import ImageAndText from "../QuestionTypes/ImageandText";
import useTimerSecond from "hooks/useTimerSecond";
import { subtractTimes } from "utils/helperfunctions/getquestiontimevalue";
import AccordionForChapterPrinciple from "views/common/AccordionForChapterPrinciple";
import AccordionForChapterExam from "views/common/AccordionForChapterExam";
import { Close } from "@mui/icons-material";
import { getCookie } from "views/common/cookieUtils";

type Props = {};
type ModalSubmitData = {
  user_id: string;
  container_id: string;
  question_id: string;
  time_taken_for_answer: any;
};

const OnlineExam = (props: Props) => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);

  const isMobile = useMediaQuery("(max-width:900px)");

  const { questions, isLoading, container_data }: any = useAppSelector(
    (state) => state.Questions
  );
  const progressBarNavigationFlag: boolean = useAppSelector(
    (state) => state.Questions.progressBarNavigationFlag
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const user_id = getCookie("id");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [modalSubmitData, setModalSubmitData] = useState<ModalSubmitData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const assignmentModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openAssignmentSuccess
  );
  const sessionModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openSessionSuccess
  );
  const showResultMode: boolean = useAppSelector(
    (state) => state.Questions.showResultModeQuiz
  );
  const showResult: boolean = useAppSelector(
    (state) => state.Questions.showResultQuiz
  );
  const showModal: boolean = useAppSelector(
    (state) => state.Questions.showModalQuiz
  );
  const assignmentData: any = localStorage.getItem("assignment");
  const assignment: any = JSON.parse(assignmentData);
  const remianTime = useAppSelector((state) => state.Questions.remainTime);
  const researchTab = useAppSelector((state) => state.Questions.researchTab);
  const previewName = useAppSelector((state) => state.Questions.previewName);
  const { time, start, setTime, reset } = useTimerSecond(
    location?.state?.examTime || "10:00"
  );
  const examTime = time;
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const containerID = searchParams.get("container_id");
  const isResearch = searchParams.get("isResearch");
  const assignment_id = searchParams.get("assignment_id");
  const isSmallScreen = useMediaQuery("(min-width: 900px)");
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );

  const defaultCourseId = getCookie("default_course_id");
  const atomicIndex = useAppSelector(
    (state) => state.StudentsHomeData.activeAtomicIndex
  );
  useEffect(() => {
    handleAssignmentData();
  }, []);

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  useEffect(() => {
    if (user_id && assignment_id && containerID) {
      dispatch(
        FetchQuestions({
          user_id: user_id,
          container_id: containerID,
          assignment_id: assignment_id,
        })
      ).then((resp: any) => {
        if (resp?.payload?.is_completed === 1) {
          dispatch(setResearchTab(true));
          reset();
          dispatch(setProgressBarFlag(true));
        } else {
          dispatch(
            setRemainTime(resp?.payload?.container_data?.total_examtime)
          );
          dispatch(
            setTotalExamTime(resp?.payload?.container_data?.total_examtime)
          );
          dispatch(setStudentLevel(resp?.payload?.student_level));
          start();
        }
      });
    }
  }, [containerID, assignment_id, user_id, dispatch]);

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  const handleNextCheckAnswer = () => {
    handleSummaryPage();
  };
  const handleNextAnswer = () => {
    dispatch(updateActiveQuestion(activeQuestion + 1));
  };

  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );

  const isTimer: boolean = useAppSelector((state) => state.Questions.isTimer);
  useEffect(() => {
    if (isTimer) {
      dispatch(setIsTimer(false));
      handleSummaryPage();
    }
  }, [isTimer]);
  const handleAssignmentData = () => {
    let arr: any = [];
    let prevContainerTypeId: number | null = null;

    atomicUnitData?.forEach((atomicData: any) => {
      if (atomicData?.meeting?.assignment?.length > 0) {
        atomicData.meeting?.assignment?.forEach((item: any) => {
          if (item.atomics_containers?.length > 0) {
            item.atomics_containers?.forEach((data: any) => {
              if (data?.assignment_id == assignment_id) {
                const matchingObject = atomicData?.meeting?.assignment.find(
                  (test: any) => test.id == assignment_id
                );
                const assignment_name = matchingObject.preview_name;
                if (matchingObject?.progress == 100) {
                  dispatch(setIconSidebar(matchingObject?.icon?.icon1));
                } else {
                  dispatch(setIconSidebar(matchingObject?.icon?.icon2));
                }
                dispatch(setPreviewName(assignment_name));
                if (
                  data.types === "container" &&
                  data.container[0]?.container_type_id == 5
                ) {
                  if (prevContainerTypeId != 5) {
                    arr.push(data);
                    prevContainerTypeId = 5;
                  }
                } else {
                  arr.push(data);
                  prevContainerTypeId = null;
                }
              }
            });
          }
        });
      }
    });

    dispatch(setSideBar(arr));
  };

  const handleSummaryPage = () => {
    dispatch(
      GetOnlineExamChapter({
        student_id: user_id ? user_id : null,
        chapter_id: container_data.id,
        assignment_id: assignment_id,
        is_next_chapter: 1, // As discuss with mehul shah by default 1
        main_container_id: containerID,
      })
    ).then((res: any) => {
      if (res?.payload?.data?.length == 0) {
        if (
          res?.payload?.data?.length == 0 &&
          assignment.length - 1 === atomicIndex
        ) {
          const currentIndex = assignment?.findIndex((item: any) => {
            return (
              item.types === "container" &&
              item.atomic_container_id == searchParams.get("container_id")
            );
          });
          dispatch(setActiveIndex(currentIndex));
          let currentElement = assignment[currentIndex];
          const meetingIndex = atomicUnitData?.findIndex((item: any) => {
            return item.meeting.assignment.some(
              (assignmentData: any) => assignmentData.id == assignment_id
            );
          });
          dispatch(
            CompleteContainerAtomic({
              student_id: user_id ? user_id : null,
              assignment_id: currentElement?.assignment_id,
              meeting_id: atomicUnitData[meetingIndex]?.meeting?.id,
              course_id: defaultCourseId ? defaultCourseId : selectedCourse,
              type: "container",
              id: currentElement?.atomic_container_id,
            })
          );
          dispatch(setResearchTab(true));
          reset();
          dispatch(setProgressBarFlag(true));
        } else {
          dispatch(setProgressBarNavigation(false));
          dispatch(setShowResultModeQuiz(false));
          dispatch(setShowResultQuiz(false));
          dispatch(setProgressBarFlag(false));
          dispatch(setQuizQuestionComplete(false));
          dispatch(setStartIndex(0));
          dispatch(updateActiveQuestion(0));
          const currentIndex = assignment?.findIndex((item: any) => {
            return (
              item.types === "container" &&
              item.atomic_container_id == searchParams.get("container_id")
            );
          });
          dispatch(setActiveIndex(currentIndex));
          let currentElement = assignment[currentIndex];
          const meetingIndex = atomicUnitData?.findIndex((item: any) => {
            return item.meeting.assignment.some(
              (assignmentData: any) => assignmentData.id == assignment_id
            );
          });
          dispatch(
            CompleteContainerAtomic({
              student_id: user_id ? user_id : null,
              assignment_id: currentElement?.assignment_id,
              meeting_id: atomicUnitData[meetingIndex]?.meeting?.id,
              course_id: defaultCourseId ? defaultCourseId : selectedCourse,
              type: "container",
              id: currentElement?.atomic_container_id,
            })
          ).then((resp: any) => {
            atomicUnitData = resp.payload.data;
            handleAssignmentData();
            if (currentIndex >= 0 && currentIndex < assignment.length - 1) {
              const nextElement = assignment[currentIndex + 1];
              if (nextElement.types == "container") {
                const examTime = nextElement?.container[0]?.container_duration;

                const container = `/question-pages?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`;

                const state = { examTime };
                navigate(container, { state });
                setTime(examTime);

                dispatch(setActiveIndex(currentIndex + 1));
              } else if (nextElement.types == "atomic") {
                const atomicType = nextElement?.atomic[0]?.types.name;
                const assignmentId = nextElement?.assignment_id;
                const atomic_id = nextElement?.atomic_container_id;
                const link = nextElement?.atomic[0]?.link;

                const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

                const state = { link };
                navigate(url, { state });

                dispatch(setActiveIndex(currentIndex + 1));
              }
            } else {
              navigate("/student-home");
            }
          });
        }
      } else {
        dispatch(setRemainTime(res?.payload.data[0]?.total_examtime));
        const time = res?.payload.data[0]?.total_examtime;
        setTime(time);
        dispatch(
          FetchQuestions({
            user_id: user_id ? user_id : null,
            container_id: res.payload.data[0].id,
            assignment_id: assignment_id,
          })
        ).then((resp) => {
          dispatch(setStudentLevel(resp?.payload?.student_level));
          dispatch(setProgressBarNavigation(false));
          dispatch(setShowResultModeQuiz(false));
          dispatch(setShowResultQuiz(false));
          dispatch(setProgressBarFlag(false));
          dispatch(setQuizQuestionComplete(false));
          dispatch(setStartIndex(0));
          dispatch(updateActiveQuestion(0));
        });
      }
    });
  };

  const handleAnswerSubmit = (data: any) => {
    if (activeQuestion + 1 == questions.length) {
      const meetingIndex = atomicUnitData?.findIndex((item: any) => {
        return item.meeting.assignment.some(
          (assignmentData: any) => assignmentData.id == assignment_id
        );
      });
    }
    if (data.answer === undefined || data.answer === "") {
      const remainingTime = subtractTimes(
        remianTime,
        data.time_taken_for_answer
      );
      setTime(remainingTime);
      setModalSubmitData(data);
      dispatch(setShowModalQuiz(true));
    } else {
      const remainingTime = subtractTimes(
        remianTime,
        data.time_taken_for_answer
      );
      setTime(remainingTime);

      // Dispatch the answer submission with the time taken
      dispatch(
        SubmitQuestionsAns({
          user_id: data.user_id,
          container_id: container_data.id,
          main_container_id: containerID,
          question_id: data.question_id,
          assignment_id: assignment_id,
          collection_id: "",
          answer: data.answer,
          is_skipped_question: 0,
          type: "Online Exam",
          notes: "",
          time_taken_for_answer: data.time_taken_for_answer, // Ensure this data is included correctly
        })
      ).then((res: any) => {
        dispatch(setRemainTime(remainingTime));
        dispatch(setStudentLevel(res?.payload?.student_level));

        if (activeQuestion + 1 === questions.length) {
          handleNextCheckAnswer(); // Handle completion of the last question
        } else {
          dispatch(updateActiveQuestion(activeQuestion + 1)); // Move to the next question
          dispatch(setShowResultQuiz(false));
        }
        dispatch(setProgressBarColor(true));
      });
    }
  };

  const handleIconCClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const pages = ["subjects", "transverse-principles"];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  const handleNavigateResearch = () => {
    navigate("/home/learning-management/");
  };
  const nextAssignment = () => {
    dispatch(setResearchTab(false));
    reset();
    dispatch(setProgressBarNavigation(false));
    dispatch(setShowResultModeQuiz(false));
    dispatch(setShowResultQuiz(false));
    dispatch(setProgressBarFlag(false));
    dispatch(setQuizQuestionComplete(false));
    dispatch(setStartIndex(0));
    dispatch(updateActiveQuestion(0));
    const meetingIndex = atomicUnitData.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    dispatch(
      GetNextAssignment({
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        meeting_id: localStorage.getItem("meetingId")
          ? localStorage.getItem("meetingId")
          : atomicUnitData[meetingIndex]?.meeting?.id,
        assignment_id: assignment_id,
        student_id: user_id ? user_id : null,
      })
    ).then((resp: any) => {
      if (resp.payload.data.length == 0) {
        if (resp.payload.meeting_completed == 1) {
          dispatch(setOpenSessionSuccess(true));
        }
        navigate("/student-home");
      } else {
        if (resp.payload.meeting_completed == 1) {
          dispatch(setOpenSessionSuccess(true));
        } else {
          dispatch(setOpenAssignmentSuccess(true));
        }

        var jsonString = JSON.stringify(resp.payload.data.atomics_containers);
        localStorage.setItem("assignment", jsonString);
        const meetingData = JSON.stringify(
          resp.payload.data.meeting_assignment.meeting_id
        );
        localStorage.setItem("meetingId", meetingData);

        dispatch(setAssignmentData(resp.payload.data.atomics_containers));
        const indexFirstNotCompleted =
          resp.payload.data.atomics_containers?.findIndex(
            (item: { is_completed: 0 }) => item.is_completed == 0
          );
        dispatch(setActiveIndex(indexFirstNotCompleted));
        const firstNotCompletedItem =
          resp.payload.data.atomics_containers[indexFirstNotCompleted];
        if (firstNotCompletedItem?.types == "container") {
          const examTime =
            firstNotCompletedItem?.container[0]?.container_duration;

          const containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}`;
          setTime(examTime);
          const state = { examTime };
          navigate(containerUrl, { state });
        } else {
          const atomicType = firstNotCompletedItem?.atomic[0]?.types.name;
          const assignmentId = firstNotCompletedItem?.assignment_id;
          const atomic_id = firstNotCompletedItem?.atomic_container_id;
          const link = firstNotCompletedItem?.atomic[0]?.link;

          const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

          const state = { link };
          navigate(url, { state });
        }
      }
    });
  };

  const AnswerScreen = () => {
    dispatch(setResearchTab(false));
    reset();
    dispatch(setProgressBarNavigation(true));
    dispatch(setNumericBar(true));
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        assignment_id: assignment_id,
        type: "Online Exam",
      })
    ).then((res) => {
      dispatch(
        FetchQuestions({
          user_id: user_id ? user_id : null,
          container_id: res.payload.chapter[0].id,
          assignment_id: assignment_id,
        })
      );
    });
    dispatch(setTimerFlag(true));
    if (showResultMode === false) {
      dispatch(setShowResultModeQuiz(true));
      dispatch(updateActiveQuestion(0));
    }
    if (activeQuestion === questions.length - 1) {
      dispatch(setShowResultQuiz(true));
      dispatch(setQuizQuestionComplete(true));
      dispatch(setQuizColorChange(true));
    } else {
      dispatch(updateActiveQuestion(activeQuestion + 1));
    }
    dispatch(setProgressBarFlag(true));
    dispatch(setQuizQuestionComplete(true));
    dispatch(updateActiveQuestion(0));
  };
  const handleClosePopup = () => {
    dispatch(setShowModalQuiz(false));
    // setShowResult(false);
  };
  const nextPage = () => {
    const totalQuestions = questions.length;

    dispatch(
      SubmitQuestionsAns({
        user_id: modalSubmitData?.user_id,
        container_id: container_data.id,
        question_id: modalSubmitData?.question_id,
        collection_id: "",
        answer: "",
        is_skipped_question: 1,
        notes: "",
        time_taken_for_answer: modalSubmitData?.time_taken_for_answer,
        type: "Online Exam",
        assignment_id: searchParams.get("assignment_id"),
        main_container_id: searchParams.get("container_id"),
      })
    ).then((res: any) => {
      const remainingTime = subtractTimes(
        remianTime,
        modalSubmitData?.time_taken_for_answer
      );
      dispatch(setRemainTime(remainingTime));
      dispatch(setStudentLevel(res?.payload?.student_level));
    });
    if (activeQuestion < totalQuestions - 1) {
      dispatch(updateActiveQuestion(activeQuestion + 1));
      dispatch(setShowModalQuiz(false));
    } else {
      handleSummaryPage();
      dispatch(setShowModalQuiz(false));
    }
  };
  const currentQuestion = activeQuestion + 1;

  return (
    <>
      {researchTab === true ? (
        <Box
          sx={{
            marginTop: { xs: "35px", md: "50px" },
            display: "block",
            height: "calc(100% - 80px)",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box marginRight={{ xs: "15%", md: "15%" }}>
            {previewName ? (
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                  marginTop: { xs: "18%", md: "0px" },
                }}
              >
                {previewName}
              </Typography>
            ) : (
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                }}
              >
                <FormattedMessage id="test-intro" />
              </Typography>
            )}
          </Box>

          <Box
            marginRight={{ xs: "5%", md: "15%" }}
            sx={{ marginTop: { xs: "20px", md: "0px" } }}
          >
            <Tabs
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#7A49B9",
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              {pages.map((page: any) => (
                <Tab
                  color="error"
                  label={<FormattedMessage id={page} />}
                  sx={{
                    color: "#878E95",
                    "&.MuiTab-root.Mui-selected": {
                      color: "#7A49B9",
                      fontWeight: 600,
                    },
                    "&.MuiTab-root:not(.Mui-selected)": {
                      borderBottom: "1px solid #878E95",
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>

          <Box
            minHeight={{ md: "calc(100vh - 132px)" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                minHeight: "auto",
                width: { xs: "95%", md: "70%" },
                maxHeight: { xs: "65vh", md: "58vh" },
                overflowY: "auto",
                marginTop: "40px",

                borderRadius: "20px",
                marginX: "auto",
              }}
            >
              {currentTab === 0 && (
                <Box>
                  <AccordionForChapterExam assignmentId={assignment_id} />
                </Box>
              )}
              {currentTab === 1 && (
                <Box>
                  <AccordionForChapterPrinciple assignmentId={assignment_id} />
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isMobile ? "" : "white",
                width: "100%",
                paddingTop: "10px",
                height: "55px",
              }}
            >
              <Button
                sx={{
                  border: "1px solid #7A49B9",
                  borderRadius: "10px",
                  marginTop: { xs: "30px", md: "0px" },
                  padding: { xs: "5px px", md: "9px 22px" },
                  backgroundColor: "#7A49B9",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", md: "18px" },
                  fontWeight: 500,
                }}
                variant="contained"
                onClick={() => AnswerScreen()}
              >
                <FormattedMessage id="complete-solutions" />
              </Button>{" "}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "relative",
                margin: "0 auto",
                height: "calc(100vh - 71px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  maxWidth: "1230px",
                  marginInline: "auto",
                  minHeight: "29px",
                  height: "fit-content",
                }}
              >
                {showResultMode === true || progressBarNavigationFlag ? (
                  <div style={{ marginTop: "60px" }}>
                    <Box
                      sx={{
                        display: { xs: "none", md: "flex" },
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        className={`s `}
                        marginRight={{
                          xs: "40px",
                          md: "20%",
                          lg: "15%",
                          xl: "25%",
                        }}
                        marginBottom="10px"
                        sx={{
                          color: "#878E95",
                          fontWeight: 100,
                          textAlign: "right",
                        }}
                      >
                        <FormattedMessage
                          id="questionInfo"
                          values={{
                            totalQuestions: questions?.length,
                            currentQuestion,
                          }}
                        />
                      </Typography>
                      <Box
                        sx={{
                          display: { xs: "none", sm: "block" },
                          width: {
                            sm: "720px",
                            md: "400px",
                            lg: "650px",
                            xl: "650px",
                          },
                          height: "1px",
                          background: "#E6E6E6",
                          marginRight: {
                            sm: "40px",
                            md: "20%",
                            lg: "15%",
                            xl: "25%",
                          },
                        }}
                      ></Box>
                    </Box>
                  </div>
                ) : (
                  <></>
                )}
              </Box>
              <AssignmentDialog openAssignmentSuccess={assignmentModal} />
              <SessionDialog openSessionSuccess={sessionModal} />
              {questions.map((item: any, index: any, array: any) => {
                return (
                  <>
                    <div>
                      {item.type.id === 1 && activeQuestion === index && (
                        <>
                          {isLoading ? (
                            <h2
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                              }}
                            >
                              Loading
                            </h2>
                          ) : (
                            <OpenTextBox
                              question={item.question}
                              onSubmit={handleAnswerSubmit}
                              question_id={item.id}
                              timesPerQuestion={examTime}
                              showResultMode={showResultMode}
                              solving_explanation={item.solving_explanation}
                              explanation_image={item.explanation_image}
                            />
                          )}
                        </>
                      )}
                      {item.type.id === 2 && activeQuestion === index && (
                        <>
                          {isLoading ? (
                            <h2
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                              }}
                            >
                              Loading
                            </h2>
                          ) : (
                            <ImageAndText
                              question_id={item.id}
                              question={item.question}
                              option_images={item.option_images}
                              options={item.options}
                              image={item.image}
                              onSubmit={handleAnswerSubmit}
                              timesPerQuestion={examTime}
                              collection={item.collections}
                              is_completed={item.is_completed}
                              is_skipped_question={item.is_skipped_question}
                              solving_explanation={item.solving_explanation}
                              showResultMode={showResultMode}
                              horizontal={item.horizontal}
                              setIsCollapsed2={setIsCollapsed2}
                              explanation_image={item.explanation_image}
                            />
                          )}
                        </>
                      )}
                      {item.type.id === 3 && activeQuestion === index && (
                        <>
                          {isLoading ? (
                            <h2
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                              }}
                            >
                              Loading
                            </h2>
                          ) : (
                            <TextImageWithQuestion
                              question={item.question}
                              option_images={item.option_images}
                              options={item.options}
                              image={item.image}
                              question_id={item.id}
                              onSubmit={handleAnswerSubmit}
                              timesPerQuestion={examTime}
                              collection={item.collections}
                              solving_explanation={item.solving_explanation}
                              showResultMode={showResultMode}
                              horizontal={item.horizontal}
                              setIsCollapsed2={setIsCollapsed2}
                              explanation_image={item.explanation_image}
                            />
                          )}
                        </>
                      )}
                      {item.type.id === 4 && activeQuestion === index && (
                        <>
                          {isLoading ? (
                            <h2
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                              }}
                            >
                              Loading
                            </h2>
                          ) : (
                            <NumericBulletSingleChoice
                              question={item.question}
                              option_images={item.option_images}
                              options={item.options}
                              image={item.image}
                              question_id={item.id}
                              onSubmit={handleAnswerSubmit}
                              timesPerQuestion={examTime}
                              collection={item.collections}
                              solving_explanation={item.solving_explanation}
                              showResultMode={showResultMode}
                              horizontal={item.horizontal}
                              setIsCollapsed2={setIsCollapsed2}
                              explanation_image={item.explanation_image}
                            />
                          )}
                        </>
                      )}
                      {item.type.id === 5 && activeQuestion === index && (
                        <LongText />
                      )}
                      {item.type.id === 6 && activeQuestion === index && (
                        <ImageQuestion />
                      )}
                      {item.type.id === 7 && activeQuestion === index && (
                        <>
                          {isLoading ? (
                            <h2
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                              }}
                            >
                              Loading
                            </h2>
                          ) : (
                            <EnglishLayout
                              question={item.question}
                              option_images={item.option_images}
                              options={item.options}
                              image={item.image}
                              question_id={item.id}
                              onSubmit={handleAnswerSubmit}
                              timesPerQuestion={examTime}
                              collection={item.collections}
                              solving_explanation={item.solving_explanation}
                              showResultMode={showResultMode}
                              horizontal={item.horizontal}
                              setIsCollapsed2={setIsCollapsed2}
                              type_id={item.type.id}
                              explanation_image={item.explanation_image}
                            />
                          )}
                        </>
                      )}
                      {item.type.id === 8 && activeQuestion === index && (
                        <Quantitative />
                      )}
                      {item.type.id === 9 && activeQuestion === index && (
                        <VerbatimText />
                      )}
                      {item.type.id === 10 && activeQuestion === index && (
                        <>
                          {isLoading ? (
                            <h2
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                              }}
                            >
                              Loading
                            </h2>
                          ) : (
                            <EnglishLayout
                              question={item.question}
                              option_images={item.option_images}
                              options={item.options}
                              image={item.image}
                              question_id={item.id}
                              onSubmit={handleAnswerSubmit}
                              timesPerQuestion={examTime}
                              collection={item.collections}
                              solving_explanation={item.solving_explanation}
                              showResultMode={showResultMode}
                              horizontal={item.horizontal}
                              setIsCollapsed2={setIsCollapsed2}
                              type_id={item.type.id}
                              explanation_image={item.explanation_image}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </>
                );
              })}
              {showResult || progressBarNavigationFlag ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#FFFFFF",
                      height: "70px",
                      width: ["-webkit-fill-available", "-moz-available"],
                      position: "fixed",
                      bottom: "0",
                      zIndex: "0",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "7A49B9",
                        my: "15px",
                        width: "152px",
                        height: "40px",
                        padding: "9px 22px",
                        borderRadius: "8px",
                      }}
                      disabled={activeQuestion == questions.length - 1}
                      onClick={() => handleNextAnswer()}
                    >
                      <FormattedMessage id="next-question" />
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #7A49B9",
                        color: "#7A49B9",
                        my: "15px",
                        marginRight: "10px",
                        height: "40px",
                        padding: "10px 30px",
                        borderRadius: "8px",
                      }}
                      onClick={
                        isResearch ? handleNavigateResearch : nextAssignment
                      }
                    >
                      <FormattedMessage id="done-researching" />
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </div>
            {showResult === true || progressBarNavigationFlag ? (
              <>
                {isCollapsed2 === true ? (
                  <>
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                        height: {
                          xs: "300px",
                          sm: "300px",
                          md: "100%",
                          lg: "100%",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "block",
                          height: "100%",
                        }}
                      >
                        <QuestionSideBar
                          question_id={questions[activeQuestion]}
                        />
                      </div>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {showModal && (
        <Dialog
          open={showModal}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"xs"}
          PaperProps={{
            sx: { borderRadius: "30px" },
          }}
        >
          <Box
            sx={{
              padding: { xs: "25px 15px ", md: "25px" },
              paddingBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button style={{ justifyContent: "end" }}>
              <Close onClick={handleClosePopup} />
            </Button>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="no-answer-popup-title" />
                </Typography>
                <Typography
                  sx={{
                    color: "#222529",
                    textAlign: "center",
                    fontWeight: { xs: 600, md: 700 },
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  <FormattedMessage id="modal-text" />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "block",
                paddingTop: "20px",
                marginInline: "auto",
              }}
            >
              <Stack direction="row" justifyContent="center" width="100%">
                <Button
                  onClick={handleClosePopup}
                  autoFocus
                  variant="contained"
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="no" />
                </Button>
                <Button
                  onClick={nextPage}
                  style={{
                    border: "1px solid #7A49B9",
                    marginInline: "5px",
                  }}
                >
                  <FormattedMessage id="yes" />
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        </Dialog>
      )}

      {allQuestionAttempt === true ? (
        <>
          <Box
            className={`${
              activeQuestion == 0 ? "question-option-mobile-4" : ""
            }`}
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "-4px 4px 4px 0px rgba(151, 151, 151, 0.25)",
              borderRadius: "0px 30px 30px 0px",
              height: "42px",
              width: "52px",
              position: "absolute",
              top: "78px",
              left: "0",
              textAlign: "center",
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
              marginTop: "12%",
            }}
          >
            <IconButton onClick={handleIconCClick}>
              <EditIconCollaps />
            </IconButton>
          </Box>
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
              position: "fixed",
              top: "78px",
              right: "auto",
              left: "0 !important",
              height: "420px",
              width: { xs: "350px", md: "300px" },
              background: "#FFFFFF",
              borderRadius: "0 30px 30px 0",
              overflow: "hidden",
            }}
          >
            <div
              className="modal-content"
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                borderRadius: "0 30px 30px 0",
              }}
            >
              <IconButton
                onClick={handleIconCClick}
                style={{ position: "absolute", left: "10px", top: "10px" }}
              >
                <EditIconCollapsModel />
              </IconButton>
              <ArrowRight
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "10px", top: "15px" }}
              />

              <QuestionSideBar question_id={questions[activeQuestion]} />
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default OnlineExam;
