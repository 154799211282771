import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  IconButton,
  Modal,
  Alert,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  GetPracticeSummary,
  PinQuestionAnswer,
  setAction,
  setNumericBar,
  setPreviewName,
  setPreviousElement,
  setProgressBarFlag,
  setProgressBarNavigation,
  setQuestionBar,
  setQuizQuestionComplete,
  setResetAss,
  setShowResult,
  setShowResultMode,
  setFetchAllSubmittedQuestions,
  UnPinQuestionAnswer,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as PushPin } from "../../assets/images/Vector.svg";
import { ReactComponent as PinFill } from "../../assets/images/Group.svg";
import { styled } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import ProgressBar from "views/QuestionPage/ProgressBar/ProgressBar";
import kidumLogo from "../../assets/images/kidum-logo.jpg";
import Sidebar from "views/QuestionPage/Sidebar/Sidebar";
import React from "react";
import AddLessonsBook from "views/learning-management/AddLessonsBook";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
  GetNextAssignment,
  setAssignmentData,
  setIconSidebar,
  setOpenAssignmentSuccess,
  setOpenSessionSuccess,
  setSideBar,
  setActiveIndex,
} from "Redux/feature/StudentHome";
import { ResetQuestionsState } from "Redux/feature/Questions";
import { ReactComponent as AddIcone } from "../../assets/images/addicon.svg";
import {
  updateQuestionData,
  updateSidebarData,
} from "Redux/feature/onlinePracticeExamSlice";
import { getCookie } from "views/common/cookieUtils";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  backgroundColor: "#F8F9FA",
  gap: "20px",
  boxSizing: "border-box",
  "@media (max-height: 650px)": {
    height: "auto",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F9FA",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F8F9FA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function PracticeSummaryQuestion() {
  const [searchParams, setSerchParams] = useSearchParams();

  const [submitStatus, setSubmitStatus] = useState<
    "success" | "error" | "none"
  >("none");
  const [showAlert, setShowAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const navigate = useNavigate();
  const containerId = searchParams.get("container_type_id");
  const assignment_id = searchParams.get("assignment_id");
  const atomicType = searchParams.get("atomic_type");
  const atomic_id = searchParams.get("atomic_id");
  const user_id = getCookie("id");
  const defaultCourseId = getCookie("default_course_id");

  const handleDialogueOpen = () => setOpenDialogue(true);
  const handleDialogueClose = () => setOpenDialogue(false);

  const atomicIndex = useAppSelector(
    (state) => state.StudentsHomeData.activeAtomicIndex
  );
  const previousElement = useAppSelector(
    (state) => state.Questions.previousElement
  );
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const resetAss = useAppSelector((state) => state.Questions.resetAss);
  const scoreModalView = {
    position: "fixed",
    top: "85px",
    right: "0",
    width: "325px",
    maxWidth: "calc(100% - 30px)",
    background: "#FFF",
    borderRadius: "20px 0 0 20px",
  };

  useEffect(() => {
    if (user_id) {
      dispatch(
        FetchQuestions({
          user_id: user_id,
          container_id: searchParams.get("container_id"),
        })
      );
      dispatch(
        GetAllSubmittedQuestion({
          user_id: user_id,
          container_id: searchParams.get("container_id"),
        })
      ).then((res: any) => {
        dispatch(setQuizQuestionComplete(true));
      });
    }
    dispatch(setQuestionBar(true));
  }, [user_id, searchParams.get("container_id")]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    const selectedObject = atomicUnitData[
      meetingIndex
    ]?.meeting?.assignment.find((item: any) => item.id == assignment_id);
    if (searchParams.get("atomic_id")) {
      const currentIndex = selectedObject?.atomics_containers?.findIndex(
        (item: any) => {
          return (
            item.types === "atomic" &&
            item.atomic[0]?.types.name === atomicType &&
            item.atomic_container_id == atomic_id
          );
        }
      );
      const nextElementData =
        selectedObject?.atomics_containers[currentIndex + 1];
      if (nextElementData?.is_completed) {
        dispatch(setPreviousElement(true));
      } else {
        dispatch(setPreviousElement(false));
      }
    } else {
      const currentIndex = selectedObject?.atomics_containers?.findIndex(
        (item: any) => {
          return (
            item.types === "container" &&
            item.atomic_container_id == searchParams.get("container_id")
          );
        }
      );
      const nextElementData =
        selectedObject?.atomics_containers[currentIndex + 1];
      if (nextElementData?.is_completed) {
        dispatch(setPreviousElement(true));
      } else {
        dispatch(setPreviousElement(false));
      }
    }

    if (
      searchParams.get("container_type_id") === "7" ||
      searchParams.get("container_type_id") === "8"
    ) {
      dispatch(setProgressBarNavigation(true));
    }
  }, [searchParams.get("container_id"), searchParams.get("atomic_id")]);

  useEffect(() => {
    handleAssignmentData();
    if (user_id) {
      dispatch(
        GetPracticeSummary({
          user_id: user_id,
          container_id: searchParams.get("container_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((resp: any) => {
        if (resp.payload.status == true) {
          const arr = resp?.payload?.data?.sidebar_data;
          dispatch(updateSidebarData(arr));
        } else {
          dispatch(updateSidebarData([]));
        }
      });
    }
    return () => {
      dispatch(updateSidebarData([]));
    };
  }, []);

  useEffect(() => {
    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item.atomic_container_id == searchParams.get("container_id")
      );
    });
    dispatch(setActiveIndex(currentIndex));
  }, [searchParams.get("container_id")]);

  const handleAssignmentData = () => {
    let arr: any = [];
    let prevContainerTypeId: number | null = null;

    atomicUnitData?.forEach((atomicData: any) => {
      if (atomicData?.meeting?.assignment?.length > 0) {
        atomicData.meeting?.assignment?.forEach((item: any) => {
          if (item.atomics_containers?.length > 0) {
            item.atomics_containers?.forEach((data: any) => {
              if (data?.assignment_id == assignment_id) {
                const matchingObject = atomicData?.meeting?.assignment.find(
                  (test: any) => test.id == assignment_id
                );
                const assignment_name = matchingObject.preview_name;
                if (matchingObject?.progress == 100) {
                  dispatch(setIconSidebar(matchingObject?.icon?.icon1));
                } else {
                  dispatch(setIconSidebar(matchingObject?.icon?.icon2));
                }
                dispatch(setPreviewName(assignment_name));
                if (
                  data.types === "container" &&
                  data.container[0]?.container_type_id == 5
                ) {
                  if (prevContainerTypeId != 5) {
                    arr.push(data);
                    prevContainerTypeId = 5;
                  }
                } else {
                  arr.push(data);
                  prevContainerTypeId = null;
                }
              }
            });
          }
        });
      }
    });

    dispatch(setSideBar(arr));
  };

  const nextAssignment = () => {
    const meetingIndex = atomicUnitData.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    dispatch(
      GetNextAssignment({
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        meeting_id: localStorage.getItem("meetingId")
          ? localStorage.getItem("meetingId")
          : atomicUnitData[meetingIndex]?.meeting?.id,
        assignment_id: assignment_id,
        student_id: user_id ? user_id : null,
      })
    ).then((resp: any) => {
      if (resp.payload.data.length == 0) {
        if (resp.payload.meeting_completed == 1) {
          dispatch(setOpenSessionSuccess(true));
        }
        navigate("/student-home");
      } else {
        if (resp.payload.meeting_completed == 1) {
          dispatch(setOpenSessionSuccess(true));
        } else {
          dispatch(setOpenAssignmentSuccess(true));
        }

        var jsonString = JSON.stringify(resp.payload.data.atomics_containers);
        localStorage.setItem("assignment", jsonString);
        const meetingData = JSON.stringify(
          resp.payload.data.meeting_assignment.meeting_id
        );
        localStorage.setItem("meetingId", meetingData);

        dispatch(setAssignmentData(resp.payload.data.atomics_containers));
        const indexFirstNotCompleted =
          resp.payload.data.atomics_containers?.findIndex(
            (item: { is_completed: 0 }) => item.is_completed == 0
          );
        dispatch(setActiveIndex(indexFirstNotCompleted));
        const firstNotCompletedItem =
          resp.payload.data.atomics_containers[indexFirstNotCompleted];
        if (firstNotCompletedItem?.types == "container") {
          const examTime =
            firstNotCompletedItem?.container[0]?.container_duration;

          const containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}`;
          const state = { examTime };
          navigate(containerUrl, { state });
        } else {
          const atomicType = firstNotCompletedItem?.atomic[0]?.types.name;
          const assignmentId = firstNotCompletedItem?.assignment_id;
          const atomic_id = firstNotCompletedItem?.atomic_container_id;
          const link = firstNotCompletedItem?.atomic[0]?.link;

          const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

          const state = { link };
          navigate(url, { state });
        }
      }
    });
  };

  const questionSummery: any = useAppSelector(
    (state) => state.Questions.questionSummery
  );
  const answer_notes_list: any = useAppSelector(
    (state) => state.Questions.answer_notes_list
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const assignmentData: any = localStorage.getItem("assignment");
  const assignment: any = JSON.parse(assignmentData);

  const previewName = useAppSelector((state) => state.Questions.previewName);
  const user_answer = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );

  // let maxTime = "00:00";

  // user_answer?.forEach((obj: any) => {
  //   if (obj?.time_taken_for_answer && obj?.time_taken_for_answer > maxTime) {
  //     maxTime = obj?.time_taken_for_answer;
  //   }
  // });

  const lessonLearn = () => {
    setOpen(true);
  };

  const handleOnlinePractice = () => {
    dispatch(updateQuestionData(false));
    if (searchParams.get("container_type_id") === "7") {
      navigate(`/student/library`, {
        state: { fromQuestionPageOfNewPractice: true },
      });
    } else {
      navigate(`/student/library`, {
        state: { fromQuestionPageOfTheOven: true },
      });
    }
  };
  const handleComplete = () => {
    assignment?.map((item: any, index: any) => {
      return null;
    });
    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item.atomic_container_id == searchParams?.get("container_id")
      );
    });
    dispatch(setActiveIndex(currentIndex));
    let currentElement = assignment[currentIndex];
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item?.meeting?.assignment?.some(
        (assignmentData: any) => assignmentData?.id == assignment_id
      );
    });
    if (user_id) {
      dispatch(
        CompleteContainerAtomic({
          student_id: user_id,
          assignment_id: currentElement?.assignment_id,
          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,

          type: "container",
          id: currentElement?.atomic_container_id,
        })
      ).then(() => {
        dispatch(
          FetchAtomicAndContainerData({
            id: user_id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        ).then((resp: any) => {
          atomicUnitData = resp.payload.data;
          handleAssignmentData();
          dispatch(setActiveIndex(currentIndex + 1));
          if (assignment.length - 1 === atomicIndex) {
            dispatch(ResetQuestionsState());
            dispatch(setActiveIndex(0));
            dispatch(setSideBar([]));
            if (resetAss) {
              navigate("/student-home");
              dispatch(setResetAss(false));
            } else {
              nextAssignment();
            }
          } else {
            if (currentIndex >= 0 && currentIndex < assignment.length - 1) {
              const nextElement = assignment[currentIndex + 1];
              if (nextElement.types == "container") {
                dispatch(setQuizQuestionComplete(false));
                dispatch(setQuizQuestionComplete(false));
                dispatch(setFetchAllSubmittedQuestions([]));
                dispatch(setProgressBarNavigation(false));
                dispatch(updateActiveQuestion(0));
                if (nextElement?.container[0]?.container_type_id == "1") {
                  dispatch(setQuestionBar(false));
                }
                if (nextElement?.container[0]?.container_type_id == "6") {
                  dispatch(setProgressBarFlag(false));
                }
                dispatch(setQuizQuestionComplete(false));
                if (resetAss || previousElement) {
                  if (
                    (resetAss &&
                      nextElement.container[0]?.container_type_id == 4) ||
                    (nextElement.container[0]?.container_type_id == 4 &&
                      previousElement)
                  ) {
                    if (nextElement.container[0]?.container_type_id == 4) {
                      dispatch(
                        GetAllSubmittedQuestion({
                          user_id: user_id,
                          container_id: nextElement?.atomic_container_id,
                        })
                      );
                    } else {
                      dispatch(
                        GetAllSubmittedQuestion({
                          user_id: user_id,
                          assignment_id: nextElement?.assignment_id,
                        })
                      );
                    }
                    navigate(
                      `/question-pages?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
                    );
                    dispatch(setAction(true));
                    dispatch(setShowResult(true));
                    dispatch(setShowResultMode(true));
                    dispatch(setNumericBar(true));
                    dispatch(setQuizQuestionComplete(true));
                  } else {
                    navigate(
                      `/practice-summary?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
                    );
                  }
                  dispatch(
                    FetchQuestions({
                      user_id: user_id ? user_id : null,
                      container_id: nextElement?.atomic_container_id,
                    })
                  );
                } else {
                  const examTime =
                    nextElement?.container[0]?.container_duration;

                  const container = `/question-pages?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`;

                  const state = { examTime };
                  navigate(container, { state });
                }
                dispatch(setActiveIndex(currentIndex + 1));
              } else if (nextElement.types == "atomic") {
                const atomicType = nextElement?.atomic[0]?.types.name;
                const assignmentId = nextElement?.assignment_id;
                const atomic_id = nextElement?.atomic_container_id;
                const link = nextElement?.atomic[0]?.link;

                const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

                const state = { link };
                navigate(url, { state });

                dispatch(setActiveIndex(currentIndex + 1));
              }
            } else {
              navigate("/student-home");
            }
          }
        });
      });
    }
  };
  const handleDecrementIndex = () => {
    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item.atomic_container_id == searchParams.get("container_id")
      );
    });
    dispatch(setActiveIndex(currentIndex));
    if (currentIndex >= 0 && currentIndex < assignment.length) {
      const nextElement = assignment[currentIndex - 1];
      if (nextElement.types == "container") {
        if (nextElement?.container[0]?.container_type_id === 4) {
          dispatch(
            GetAllSubmittedQuestion({
              user_id: user_id,
              container_id: nextElement?.atomic_container_id,
            })
          );
          navigate(
            `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
          );
          dispatch(setAction(true));
          dispatch(setShowResult(true));
          dispatch(setShowResultMode(true));
          dispatch(setNumericBar(true));
          dispatch(setQuizQuestionComplete(true));
        } else {
          navigate(
            `/practice-summary?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
          );
        }
        dispatch(setActiveIndex(currentIndex - 1));
      } else if (nextElement.types == "atomic") {
        const atomicType = nextElement?.atomic[0]?.types.name;
        const assignmentId = nextElement?.assignment_id;
        const atomic_id = nextElement?.atomic_container_id;
        const link = nextElement?.atomic[0]?.link;

        const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

        const state = { link };
        navigate(url, { state });
        dispatch(setActiveIndex(currentIndex - 1));
      }
    }
  };

  const handlePinClick = (row: any) => {
    dispatch(
      PinQuestionAnswer({
        student_id: row.student_id,
        notes_id: row.id,
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
      })
    ).then((resp: any) => {
      if (resp.payload.status === false && resp.payload.code === 200) {
        setSubmitStatus("success");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    });
  };

  const handleUnPinClick = (row: any) => {
    dispatch(
      UnPinQuestionAnswer({
        student_id: row.student_id,
        notes_id: row.id,
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
      })
    );
  };
  return (
    <>
      <AddLessonsBook open={open} setOpen={setOpen} />
      <Grid
        container
        wrap="nowrap"
        sx={{ boxSizing: "border-box", height: "100%" }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <Sidebar currentStep={4} />
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <Box
            onClick={handleDialogueOpen}
            sx={{
              display: { xs: "flex", md: "flex" },
              position: "absolute",
              top: "75px",
              right: "15px",
              width: "auto",

              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            {previewName ? (
              <Typography variant="body2" marginRight={1} marginLeft={1}>
                {previewName}
              </Typography>
            ) : (
              <Typography variant="body2" marginRight={1} marginLeft={1}>
                <FormattedMessage id="task-name" />
              </Typography>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appBar"
              aria-haspopup="true"
              color="inherit"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 6H3"
                  stroke="#222529"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 12H3"
                  stroke="#222529"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 18H3"
                  stroke="#222529"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21 6H20.99"
                  stroke="#222529"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21 12H20.99"
                  stroke="#222529"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21 18H20.99"
                  stroke="#222529"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          </Box>
          <Box sx={{ position: "absolute", top: "75px", left: 0 }}>
            <Button onClick={lessonLearn}>
              <AddIcone />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "block",
            height: "calc(100% - 80px)",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "6px",
            }}
          >
            <ProgressBar containerType={containerId} />
          </Box>
          <Box sx={{ padding: { xs: "0 7px", md: "0 7px" } }}>
            <Box
              component="img"
              className="header-logo"
              src={kidumLogo}
              alt="kidum mmm"
              sx={{
                position: "absolute",
                top: "25px",
                display: { md: "none" },
                marginRight: "10px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              position: "relative",
              justifyContent: "flex-start",
              width: "100%",
              borderBottom: "1px solid #E8EBED",
            }}
          ></Box>
          <CustomBox>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  columnGap: "10px",
                  paddingTop: 34,
                }}
              >
                <Box style={{ paddingBottom: 25 }}>
                  <Typography
                    variant="h4"
                    sx={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    <FormattedMessage id="practice-summary" />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    style={{
                      border: "1px solid #DFE2E5",

                      borderRadius: 7,
                      background: "#FFFFFF",
                    }}
                    width={{ xs: "142px", md: "122px" }}
                    height={{ xs: "68px", md: "80px" }}
                    padding={{
                      xs: "6px 15px 6px 15px",
                      md: "20px 0px 0px 0px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        color: "#3397DB",
                      }}
                    >
                      {/* {questionSummery.total_correct_answer_given} */}
                      {questionSummery.length > 0 ||
                      questionSummery.total_correct_answer_given
                        ? questionSummery.total_correct_answer_given
                        : 0}
                      <span style={{ fontSize: "12px", fontWeight: 400 }}>
                        /{/* {questionSummery.total_questions} */}
                        {questionSummery.length > 0 ||
                        questionSummery.total_questions
                          ? questionSummery.total_questions
                          : 0}
                      </span>
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#AEB5BC",
                        paddingTop: 1,
                        lineHeight: "16.32px",
                      }}
                    >
                      <FormattedMessage id="settlement" />
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      border: "1px solid #DFE2E5",

                      borderRadius: 7,
                      background: "#FFFFFF",
                    }}
                    width={{ xs: "142px", md: "122px" }}
                    height={{ xs: "68px", md: "80px" }}
                    padding={{
                      xs: "6px 15px 6px 15px",
                      md: "20px 0px 0px 0px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        color: "#3397DB",
                      }}
                    >
                      {/* {searchParams.get("container_type_id") == "2" ||
                      searchParams.get("container_type_id") == "6"
                        ? maxTime != "00:00"
                          ? maxTime
                          : questionSummery?.total_time_taken
                        : questionSummery?.total_time_taken} */}
                      {questionSummery.total_time_taken}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#AEB5BC",
                        paddingTop: 1,
                        lineHeight: "16.32px",
                      }}
                    >
                      <FormattedMessage id="practice-time" />
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: "100%", flexGrow: "1" }}>
              <Stack direction="row" height="100%">
                <Stack
                  flexGrow={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      padding: "0 15px",
                      boxSizing: "border-box",
                    }}
                    display={{ xs: "none", md: "none", lg: "flex" }}
                  >
                    <TableContainer
                      component={Paper}
                      sx={{
                        border: "1px solid #DFE2E5",
                        marginInline: "auto",
                        borderRadius: "20px",
                        filter:
                          "drop-shadow(0px 5px 16px rgba(8, 15, 52, 0.05))",
                        width: { xs: "100%", md: "100%", lg: "950px" },
                        maxHeight: "440px",
                        overflow: "auto",
                      }}
                    >
                      <Table
                        sx={{
                          width: "100%",
                        }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #DFE2E5",
                                paddingBlock: 12,
                                color: "#878E95",
                                fontWeight: 500,
                              }}
                              align="center"
                            >
                              <FormattedMessage id="question" />
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #DFE2E5",
                                paddingBlock: 12,
                                color: "#878E95",
                                fontWeight: 500,
                              }}
                              align="center"
                            >
                              <FormattedMessage id="topic" />
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #DFE2E5",
                                paddingBlock: 12,
                                color: "#878E95",
                                fontWeight: 500,
                              }}
                              align="center"
                            >
                              <FormattedMessage id="lesson" />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {answer_notes_list?.map((row: any) => {
                            return (
                              row.notes !== "" && (
                                <StyledTableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {row.notes && (
                                    <>
                                      <StyledTableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #DFE2E5",
                                          paddingBlock: 8,
                                          fontWeight: 400,
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        {/* <FormattedMessage id="question" />{" "} */}
                                        {row?.action_question
                                          ? row.action_question
                                          : row?.question_number}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #DFE2E5",
                                          paddingBlock: 8,
                                          fontWeight: 400,
                                        }}
                                      >
                                        {row?.topic?.name ? (
                                          row.topic.name
                                        ) : (
                                          <FormattedMessage id="equations" />
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #DFE2E5",
                                          paddingBlock: 8,
                                          color: "#4A5056",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: row.notes,
                                          }}
                                        ></div>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #DFE2E5",
                                          paddingBlock: 8,
                                          width: 15,
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            ml: 1,
                                            "&.MuiButtonBase-root:hover": {
                                              bgcolor: "transparent",
                                            },
                                          }}
                                        >
                                          {row.is_pinned === 0 && (
                                            <>
                                              <PushPin
                                                onClick={() =>
                                                  handlePinClick(row)
                                                }
                                              />
                                            </>
                                          )}
                                          {row.is_pinned === 1 && (
                                            <>
                                              <PinFill
                                                onClick={() =>
                                                  handleUnPinClick(row)
                                                }
                                              />
                                            </>
                                          )}
                                        </Button>
                                      </StyledTableCell>
                                    </>
                                  )}
                                </StyledTableRow>
                              )
                            );
                          })}

                          {answer_notes_list?.filter(
                            (row: any) => row.notes !== ""
                          ).length < 1 && (
                            <>
                              <StyledTableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    paddingBlock: 10,
                                    borderBottom: "none",
                                    textAlign: "center",
                                  }}
                                >
                                  <Box
                                    textAlign={"center"}
                                    paddingTop={8}
                                    paddingBottom={12}
                                  >
                                    <Typography
                                      variant="h4"
                                      sx={{
                                        fontSize: "20px",
                                        fontWeight: 400,
                                        color: "#222529",
                                      }}
                                    >
                                      <FormattedMessage id="lessons-investigation" />
                                    </Typography>
                                    <Typography
                                      variant="h4"
                                      sx={{
                                        fontSize: "20px",
                                        fontWeight: 700,
                                        color: "#222529",
                                      }}
                                    >
                                      <FormattedMessage id="lessons-beneficial" />
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </StyledTableRow>
                            </>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableCell colSpan={4} style={{ paddingBlock: 10 }}>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "primary",
                                height: "40px",
                                padding: "9px 22px",
                                borderRadius: "8px",
                                mx: "auto",
                              }}
                              style={{ display: "block" }}
                              onClick={lessonLearn}
                            >
                              + <FormattedMessage id="added-lesson" />
                            </Button>
                          </TableCell>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    display={{ xs: "flex", md: "flex", lg: "none" }}
                    marginLeft="0px"
                    marginBottom={{ xs: "90px", md: "auto" }}
                    width="100%"
                  >
                    {answer_notes_list && answer_notes_list.length > 0 ? (
                      answer_notes_list.map((row: any) => (
                        <Grid
                          item
                          xs={12}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            paddingLeft: "0px",
                          }}
                        >
                          <Card
                            style={{
                              background: "#F8F9FA",
                              borderTopRightRadius: "20px",
                              borderTopLeftRadius: "20px",
                              borderBottomRightRadius: "20px",
                              borderBottomLeftRadius: "20px",
                              width: "335px",
                            }}
                          >
                            <CardContent
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                background: "#F1F3F5",
                                padding: "5px",
                                alignItems: "center",
                                paddingLeft: "10px",
                              }}
                            >
                              <Button
                                sx={{
                                  ml: 1,
                                  "&.MuiButtonBase-root:hover": {
                                    bgcolor: "transparent",
                                  },
                                }}
                              >
                                {row.is_pinned === 0 && (
                                  <>
                                    <PushPin
                                      onClick={() => handlePinClick(row)}
                                    />
                                  </>
                                )}
                                {row.is_pinned === 1 && (
                                  <>
                                    <PinFill
                                      width="50px"
                                      height="25px"
                                      onClick={() => handleUnPinClick(row)}
                                    />
                                  </>
                                )}
                              </Button>
                              {/* manage the exception handling for the name is null or blank */}
                              {row.topic == null ? (
                                <>
                                  {" "}
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      color: "#4A5056",
                                    }}
                                  >
                                    -
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      color: "#4A5056",
                                      marginLeft: "auto",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {row.topic.name}
                                  </Typography>
                                </>
                              )}

                              {/* <Typography>
                                {date}/{month}
                              </Typography> */}
                            </CardContent>
                            <CardContent
                              style={{ borderBlock: "1px solid #DFE2E5" }}
                            >
                              <Typography
                                component="p"
                                style={{
                                  textAlign: "center",
                                  fontWeight: 600,
                                  fontSize: "18px",
                                  color: "#353A3F",
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: row.notes,
                                  }}
                                ></div>
                              </Typography>
                            </CardContent>
                            <CardActions
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                component="span"
                                style={{ fontWeight: 600, color: "#4A5056" }}
                              >
                                <FormattedMessage id="question" />{" "}
                                {row?.action_question
                                  ? row.action_question
                                  : row?.question_number}
                              </Typography>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            paddingLeft: "0px",
                          }}
                        >
                          <Card
                            sx={{
                              background: "#FFFFFF",

                              borderRadius: "8px",
                              height: "134px",
                              width: "335px",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: 400,
                                color: "#222529",
                                paddingTop: "40px",
                              }}
                            >
                              לא הוספת לקחים במהלך התחקור..
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "18px",
                                fontWeight: 600,
                                color: "#353A3F",
                              }}
                            >
                              הוספת לקחים מועילה לבריאות{" "}
                            </Typography>
                          </Card>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Box
                    display="flex"
                    justifyContent="center"
                    borderTop={1}
                    borderColor="divider"
                    sx={{
                      marginTop: { xs: "12px", md: "auto" },
                      width: "100%",
                      backgroundColor: "#fff",
                      zIndex: 1,
                      position: { xs: "fixed", md: "relative" },
                      bottom: { xs: "0px", md: "auto" },
                    }}
                  >
                    {atomicIndex != 0 &&
                    !(containerId === "7" || containerId === "8") ? (
                      <>
                        <Button
                          variant="outlined"
                          sx={{
                            my: "15px",
                            height: "40px",
                            padding: "9px 22px",
                            borderRadius: "8px",
                            mx: "8px",
                          }}
                          onClick={handleDecrementIndex}
                        >
                          <FormattedMessage id="previous-stage" />
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "primary",
                        my: "15px",
                        height: "40px",
                        padding: "9px 22px",
                        borderRadius: "8px",
                      }}
                      onClick={
                        searchParams.get("container_type_id") == "7" ||
                        searchParams.get("container_type_id") == "8"
                          ? handleOnlinePractice
                          : handleComplete
                      }
                    >
                      <FormattedMessage id="end-investigation" />
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </CustomBox>
        </Box>
        <Box>
          <Modal
            open={openDialogue}
            onClose={handleDialogueClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <>
              <Box sx={scoreModalView}>
                <Box
                  onClick={handleDialogueClose}
                  sx={{
                    display: { xs: "flex" },
                    cursor: "pointer",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Box sx={{ display: { xs: "flex" }, pl: "15px" }}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12H19"
                        stroke="#4A5056"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 5L19 12L12 19"
                        stroke="#4A5056"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "flex" },
                      width: "124px",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                    }}
                  >
                    {previewName ? (
                      <Typography
                        variant="body2"
                        marginRight={1}
                        marginLeft={1}
                      >
                        {previewName}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        marginRight={1}
                        marginLeft={1}
                      >
                        <FormattedMessage id="task-name" />
                      </Typography>
                    )}
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appBar"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 6H3"
                          stroke="#222529"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 12H3"
                          stroke="#222529"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 18H3"
                          stroke="#222529"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 6H20.99"
                          stroke="#222529"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 12H20.99"
                          stroke="#222529"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 18H20.99"
                          stroke="#222529"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </Box>
                </Box>

                <Box
                  className="mobile-sidebar-menulist"
                  sx={{ height: "400px", overflow: "auto" }}
                >
                  <Sidebar currentStep={4} />
                </Box>
              </Box>
            </>
          </Modal>
        </Box>
      </Grid>
      {submitStatus === "success" && (
        <>
          {showAlert && (
            <Alert
              className="removeIconLessonBooksAlert"
              sx={{
                width: "178px",
                height: "42px",
                backgroundColor: "#E74C3C",
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                fontWeight: 600,
                fontSize: "16px",
                position: { xs: "fixed", md: "absolute" },
                bottom: "60px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
              }}
            >
              <FormattedMessage id="uptoFiveLessonsEmbedded" />
            </Alert>
          )}
        </>
      )}
    </>
  );
}

export default PracticeSummaryQuestion;
