import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTimer } from "hooks/useTimer";
import { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { subtractTimes } from "../../../utils/helperfunctions/getquestiontimevalue";
import { useNavigate, useSearchParams } from "react-router-dom";
import TimerIcon from "../../../assets/images/timer.svg";
import { useAppSelector, useAppDispatch } from "Redux/App/hooks";
import DescriptionBox from "../DescriptionBox";
import {
  GetAllSubmittedQuestion,
  GetAllSubmittedQuestionForOnlineExam,
  setIsTimer,
  setProgressBarNavigation,
  setQuizQuestionComplete,
  SubmitQuestionsAns,
  SubmitQuestionsAnsForOnlineExam,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import { ReactComponent as WhiteTimerIcon } from "../../../assets/images/WhiteTimerIcon.svg";
import { useWirisParser } from "hooks/useWiris";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
} from "Redux/feature/StudentHome";
import { getCookie } from "views/common/cookieUtils";

type Props = {
  question: string;
  onSubmit: Function;
  question_id: any;
  solving_explanation: any;
  timesPerQuestion: any;
  showResultMode: boolean;
  explanation_image?: any;
  score?: string;
};

const OpenTextBox = ({
  question,
  onSubmit,
  question_id,
  solving_explanation,
  timesPerQuestion,
  showResultMode,
  explanation_image,
  score,
}: Props) => {
  const questionRef = useRef(null);
  useWirisParser(questionRef);

  const dispatch = useAppDispatch();
  const [openTextBoxAnswer, setopenTextBoxAnswer] = useState<any>("");
  const [user_id, setUserId] = useState<any>();
  const navigate = useNavigate();
  const [searchParams, setSerchParams] = useSearchParams();
  const [showTime, setShowTime] = useState(false);
  const [renderImg, setRenderImg] = useState<any>(false);
  const [textColor, setTextColor] = useState<string>();
  const [color, setColor] = useState<string>();
  let userId = getCookie("id");
  const [showQuestion, setShowQuestion] = useState<boolean>(false);
  let timer = timesPerQuestion;
  const time = useTimer(timer);
  const isMediumScreen = useMediaQuery("(min-width: 900px)");
  let selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const student_level = useAppSelector(
    (state) => state?.Questions?.student_level
  );
  const remainingTime: any = useAppSelector(
    (state) => state.Questions.remainTime
  );
  const remainingTimeQuiz: any = useAppSelector(
    (state) => state.Questions.remainTimeQuiz
  );
  const remainingTimeSeries: any = useAppSelector(
    (state) => state.Questions.remainTimeSeries
  );
  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );
  const questionDetail = fetchAllSubmittedQuestionAnswer?.find((q: { question_id: any; }) => q.question_id === question_id);
  
  const assignment_id = searchParams.get("assignment_id");
  const containerID = searchParams.get("container_id");

  const handleChange = (e: any) => {
    setopenTextBoxAnswer(e.target.value);
  };
  const handleTextColor = () => {
    let id: any = searchParams.get("container_type_id");
    if (id == 1 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 2 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 3 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 6 && isAllQuestionAttempt === false) {
      setTextColor("white");
    } else if (id == 7 && isAllQuestionAttempt === false) {
      setTextColor("#878E95");
    } else if (id == 8 && isAllQuestionAttempt === false) {
      setTextColor("#878E95");
    } else if (id == 10 && isAllQuestionAttempt === false) {
      setTextColor("white");
    } else {
      setTextColor("black");
    }
  };
  const handleColor = () => {
    let id: any = searchParams.get("container_type_id");
    if (id == 1 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 2 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 3 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 6 && isAllQuestionAttempt === false) {
      setColor("white");
    } else if (id == 7 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 8 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 10 && isAllQuestionAttempt === false) {
      setTextColor("white");
    } else {
      setColor("#878E95");
    }
  };
  const handleTimerIconClick = () => {
    setShowTime(!showTime);
    let id: any = searchParams.get("container_type_id");
    if (id === "1" || id === "2" || id === "3" || id === "7" || id === "8") {
      if (!isAllQuestionAttempt) {
        setRenderImg(true);
      } else {
        setRenderImg(false);
      }
    }
  };

  useEffect(() => {
    if (searchParams.get("container_type_id") == "10") {
      setColor("white");
    }
  }, [searchParams.get("container_type_id") == "10"]);

  useEffect(() => {
    handleTextColor();
    handleColor();
    handleTimerIconClick();
    setUserId(userId);
  }, [userId]);
  const { questions, container_data }: any = useAppSelector(
    (state) => state.Questions
  );
  const isAllQuestionAttempt = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const totalExamTime: any = useAppSelector(
    (state) => state.Questions.totalExamTime
  );
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const currentQuestion = activeQuestion + 1;
  const defaultCourseId = getCookie("default_course_id");

  const allQuestionContainerID = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.saveOnlinePracticeInfo.id
  );

  const ActiveQuestionDifficultyLevel = useAppSelector(
    (state) => state.Questions?.questions[activeQuestion]?.difficulty_level
  );
  let differenceValue: any;
  const containerId = searchParams.get("container_type_id");
  if (
    (containerId && parseInt(containerId) === 1 && container_data) ||
    (containerId && parseInt(containerId) === 7 && container_data) ||
    (containerId && parseInt(containerId) === 8 && container_data)
  ) {
    differenceValue = subtractTimes(timesPerQuestion, time);
  } else if (containerId && parseInt(containerId) === 10 && container_data) {
    differenceValue = subtractTimes(remainingTime, timesPerQuestion);
  }else if (containerId && parseInt(containerId) === 6 && container_data) {
    differenceValue = subtractTimes(remainingTimeQuiz, timesPerQuestion);
  }
  else if (containerId && parseInt(containerId) === 2 && container_data) {
    differenceValue = subtractTimes(remainingTimeSeries, timesPerQuestion);
  }  else {
    differenceValue = subtractTimes(totalExamTime, timesPerQuestion);
  }
  useEffect(() => {
    setUserId(userId);
  }, [userId]);
  const handleTimeClick = () => {
    setShowTime(!showTime);
  };
  useEffect(() => {
    if (containerId === "1" && time === "00:00") {
      dispatch(
        SubmitQuestionsAns({
          user_id: userId,
          container_id: searchParams.get("container_id"),
          question_id: question_id,
          collection_id: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
        })
      ).then((res: any) => {
        dispatch(setProgressBarNavigation(true));
        dispatch(
          GetAllSubmittedQuestion({
            user_id: userId,
            container_id: searchParams.get("container_id"),
          })
        );
        dispatch(updateActiveQuestion(activeQuestion));
        dispatch(setQuizQuestionComplete(true));
      });
    }
  }, [differenceValue]);

  useEffect(() => {
    if ((containerId === "6" || containerId === "2") && time === "00:00") {
      dispatch(
        SubmitQuestionsAns({
          user_id: userId,
          container_id: searchParams.get("container_id"),
          question_id: question_id,
          collection_id: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
        })
      ).then((res: any) => {
        const meetingIndex = atomicUnitData?.findIndex((item: any) => {
          return item.meeting.assignment.some(
            (assignmentData: any) => assignmentData.id == assignment_id
          );
        });
        if (userId) {
          dispatch(
            CompleteContainerAtomic({
              student_id: userId,
              assignment_id: searchParams.get("assignment_id"),
              meeting_id: localStorage.getItem("meetingId")
                ? localStorage.getItem("meetingId")
                : atomicUnitData[meetingIndex]?.meeting?.id,
              course_id: defaultCourseId ? defaultCourseId : selectedCourse,

              type: "container",
              id: searchParams.get("container_id"),
            })
          ).then((resp: any) => {
            dispatch(
              FetchAtomicAndContainerData({
                id: userId ? userId : null,
                course_id: defaultCourseId ? defaultCourseId : selectedCourse,
              })
            );
            navigate(
              `/practice-summary?container_type_id=${searchParams.get(
                "container_type_id"
              )}&container_id=${searchParams.get(
                "container_id"
              )}&assignment_id=${searchParams.get("assignment_id")}`
            );
          });
        }
      });
    }
  }, [time]);
  useEffect(() => {
    if (containerId === "10" && time === "00:00") {
      dispatch(
        SubmitQuestionsAns({
          user_id: userId,
          question_id: question_id,
          collection_id: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
          container_id: container_data.id,
          main_container_id: containerID,
          assignment_id: assignment_id,
          type: "Online Exam",
        })
      ).then((res: any) => {
        dispatch(setIsTimer(true));
      });
    }
  }, [time]);
  useEffect(() => {
    if ((containerId === "7" || containerId === "8") && time === "00:00") {
      dispatch(
        SubmitQuestionsAnsForOnlineExam({
          student_id: userId,
          practice_id: allQuestionContainerID,
          question_id: question_id,
          collection_id: "",
          answer: "",
          explanation_image: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
          level: ActiveQuestionDifficultyLevel,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((res: any) => {
        dispatch(setProgressBarNavigation(true));
        dispatch(
          GetAllSubmittedQuestionForOnlineExam({
            student_id: userId,
            practice_id: allQuestionContainerID,
            question_id: question_id,
            collection_id: "",
            answer: "",
            explanation_image: "",
            is_skipped_question: 1,
            notes: "",
            time_taken_for_answer: differenceValue,
            level: ActiveQuestionDifficultyLevel,
          })
        );
        dispatch(updateActiveQuestion(activeQuestion));
        dispatch(setQuizQuestionComplete(true));
      });
    }
    if (containerId === "7" || containerId === "8") {
      dispatch(setProgressBarNavigation(false));
    }
  }, [differenceValue]);
  let lastIndex = questions.length - 1;

  return (
    <>
      <Grid
        xs={12}
        sm={6}
        sx={{
          margin: "auto",
          maxWidth: "1230px",
          width: "calc(100% - 15px)",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "column", md: "row-reverse" },
          gap: "10px",
          paddingTop: {
            xs: containerId === "1" ? "0px" : "25px",
            md: "25px",
          },
          alignItems: { xs: "center", sm: "center", md: "unset" },
        }}
      >
        {isAllQuestionAttempt === true &&
          (containerId === "7" || containerId === "8") && (
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "95%",
                marginBottom: "20px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "black",
                    marginLeft: "75px",
                  }}
                >
                  <FormattedMessage id="insight-question-title" />
                </Typography>
              </Box>
              <Box>
                {" "}
                <Box
                  onClick={handleTimeClick}
                  className={`${
                    activeQuestion == 0
                      ? "question-option-2 question-option-mobile-2"
                      : ""
                  }`}
                  sx={{
                    border: "1px solid #3397DB",
                    fontWeight: "600",
                    borderRadius: "20px",
                    padding: "5px 15px 5px 15px",
                    display: "flex",
                    alignItems: "flex-end",
                    color: "#3397DB",
                    backgroundColor: "#F2F8FD",
                    fontSize: "14px",
                    lineHeight: "19px",
                    cursor: "pointer",
                    position: "absolute",
                    top: {
                      xs:
                        containerId === "7" ||
                        containerId === "8" ||
                        containerId === "1" ||
                        containerId === "2"
                          ? "22px"
                          : "71px",
                      md:
                        containerId === "7" || containerId === "8"
                          ? "25px"
                          : "71px",
                    },
                    left: "15px",
                  }}
                >
                  {time}
                </Box>{" "}
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: textColor,
                    marginLeft: "75px",
                  }}
                >
                  <FormattedMessage id="practice-time" />
                </Typography>
              </Box>
            </Box>
          )}
        <Box
          sx={{
            maxWidth: "724px",
            height: "auto",
            flexDirection: "column",
            gap: containerId === "1" ? "25px" : "15px",
            position: "relative",
            margin: "0 auto",
            paddingBottom:
              isAllQuestionAttempt === true || containerId === "1"
                ? "15px"
                : "0 auto",
            borderBottom:
              isAllQuestionAttempt === true || containerId === "1"
                ? "1px solid #E6E6E6"
                : "",
          }}
        >
          <Box
            sx={{
              display:
                containerId === "6" || containerId === "10" ? "none" : "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
              alignItems: containerId === "1" ? "flex-end" : "",
              marginTop: {
                xs: containerId === "2" ? "10px" : "0px",
                md: "0px",
              },
              height: {
                xs:
                  containerId === "7" ||
                  containerId === "8" ||
                  containerId === "2"
                    ? "30px"
                    : containerId === "1"
                    ? "18px"
                    : "75px",
                md:
                  containerId === "7" || containerId === "8"
                    ? "30px"
                    : containerId === "2"
                    ? "75px"
                    : containerId === "1"
                    ? "18px"
                    : "75px",
              },
            }}
          >
            {/* <div style={{
              position: "absolute", top: "43px"
            }}>
              {questions[activeQuestion]?.question[activeQuestion].length > 100 ? <Button onClick={handleOpen}><ViewColumn /></Button> : <></>}
              {isAllQuestionAttempt === true && questions[activeQuestion]?.solving_explanation.length > 100 ? <Button onClick={handleOpen}><ViewColumn /></Button> : <></>}
            </div> */}
            {isAllQuestionAttempt === true && containerId === "1" ? (
              <>
                <Box
                  onClick={handleTimeClick}
                  sx={{
                    fontWeight: "600",
                    borderRadius: "20px",
                    padding: "5px 15px 5px 15px",
                    display: !isMediumScreen ? "flex" : "none",
                    alignItems: "flex-end",
                    color: "#3397DB",
                    backgroundColor: "#F2F8FD",
                    fontSize: "18px",
                    lineHeight: "18px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "-6px",
                    left: "5px",
                  }}
                >
                  {time}
                </Box>{" "}
              </>
            ) : (
              <>
                <Box
                  style={{ position: "absolute", top: "-23px", left: "-32px" }}
                >
                  {showTime ? (
                    <Box
                      onClick={handleTimeClick}
                      sx={{
                        border: "1px solid #3397DB",
                        fontWeight: "600",
                        borderRadius: "20px",
                        padding: "5px 15px 5px 15px",
                        display: "flex",
                        alignItems: "flex-end",
                        color:
                          containerId === "6" || containerId === "10"
                            ? "black"
                            : "#3397DB",
                        backgroundColor: "#F2F8FD ",
                        fontSize: "14px",
                        lineHeight: "19px",
                        cursor: "pointer",
                        position: "absolute",
                        top: {
                          xs:
                            containerId === "7" ||
                            containerId === "8" ||
                            containerId === "2"
                              ? "22px"
                              : containerId === "1"
                              ? "16px"
                              : "71px",
                          md:
                            containerId === "7" || containerId === "8"
                              ? "25px"
                              : containerId === "1"
                              ? "16px"
                              : "75px",
                        },
                        left: "30px",
                      }}
                    >
                      {time}
                    </Box>
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 0,
                      }}
                    >
                      {renderImg === true ? (
                        <Box
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            top:
                              containerId === "7" || containerId === "1"
                                ? "27px"
                                : "78px",
                            left: "64px",
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <img
                            className="library-item-img"
                            src={TimerIcon}
                            alt="TimerIcon"
                            onClick={handleTimerIconClick}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            top:
                              containerId === "7" || containerId === "1"
                                ? { xs: "27px", md: "78px" }
                                : "78px",
                            left: "64px",
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <WhiteTimerIcon onClick={handleTimerIconClick} />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}

            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: {
                  xs:
                    containerId === "7" ||
                    containerId === "8" ||
                    containerId === "2"
                      ? "flex-start"
                      : "flex-end",
                  md: "flex-end",
                },
                gap: "7.5px",
                marginLeft: "74px",
              }}
            >
              {isAllQuestionAttempt === true ? (
                <></>
              ) : (
                <>
                  {containerId != "1" && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: color,
                      }}
                    >
                      {containerId == "7" || containerId == "8" ? (
                        <></>
                      ) : (
                        <FormattedMessage id="practice-time" />
                      )}{" "}
                    </Typography>
                  )}
                </>
              )}

              {isAllQuestionAttempt === true ? (
                <></>
              ) : (
                <>
                  {" "}
                  {containerId != "1" && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: textColor,
                      }}
                    >
                      {containerId == "7" || containerId == "8" ? (
                        <></>
                      ) : (
                        <b>
                          {containerId == "1"
                            ? timesPerQuestion
                            : container_data.total_examtime}
                        </b>
                      )}{" "}
                    </Typography>
                  )}
                </>
              )}

              {isAllQuestionAttempt === true ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: color,
                      marginLeft: "10px",
                      display:
                        !isMediumScreen && containerId === "1"
                          ? "flex"
                          : "none",
                    }}
                  >
                    <FormattedMessage id="practice-time" />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: {
                        xs:
                          containerId == "7" || containerId == "8"
                            ? "16px"
                            : "14px",
                      },
                      fontWeight: 400,
                      color: color,
                    }}
                  >
                    <FormattedMessage id="exam-time" />
                    {(containerId == "7" || containerId == "8") && <>:</>}
                  </Typography>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: "block",
                alignItems:
                  containerId === "7" || containerId === "8"
                    ? "flex-start"
                    : "flex-end",
                md: "flex-end",

                marginTop: {
                  xs:
                    containerId === "1" || containerId === "2" ? "0px" : "20px",
                  md:
                    containerId == "2"
                      ? "20px"
                      : containerId === "1"
                      ? "0px"
                      : "20px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: { xs: "center", md: "right" },
                  fontWeight: {
                    xs:
                      containerId === "7" || containerId === "8"
                        ? "600"
                        : "400",
                    md:
                      containerId === "7" || containerId === "8"
                        ? "400"
                        : "400",
                  },
                  color: {
                    xs:
                      containerId === "7" || containerId === "8"
                        ? "#353A3F"
                        : color,
                    md: color,
                  },
                  marginTop: {
                    xs:
                      containerId === "1" || containerId === "2"
                        ? "0px"
                        : "30px",
                    md: containerId === "1" ? "0px" : "30px",
                  },
                  display: "flex",
                  marginRight: {
                    xs: containerId === "1" ? "55px" : "0px",
                    md: containerId === "1" ? "10px" : "0px",
                  },
                }}
              >
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />
              </Typography>
            </Box>
          </Box>
          {/* containerId 6  */}
          <Box
            sx={{
              display: {
                xs:
                  (containerId === "6" || containerId === "10") &&
                  isAllQuestionAttempt === true
                    ? "flex"
                    : "none",
                md: "none",
              },
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "column",
              width:
                containerId === "6" || containerId === "10" ? "95%" : "100%",
              margin:
                containerId === "6" || containerId === "10" ? "0 auto " : 0,
              marginTop:
                containerId === "6" || containerId === "10" ? "15px" : 0,
              height: "100%",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#222529",
              }}
            >
              {" "}
              {containerId == "6" || containerId == "10" ? (
                container_data?.display_name
              ) : (
                <FormattedMessage id="examines-analogies" />
              )}
              {containerId == "10" && isAllQuestionAttempt == false && (
                <>({student_level})</>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {" "}
                <>
                {
    containerId == "6" ? 
    <FormattedMessage id="answer-question" /> : 
    <FormattedMessage id="exam-time" />
}:
                  <Box
                    onClick={handleTimeClick}
                    className={`${
                      activeQuestion == 0
                        ? "question-option-2 question-option-mobile-2"
                        : ""
                    }`}
                    sx={{
                      border: "1px solid #3397DB",
                      fontWeight: "600",
                      borderRadius: "20px",
                      padding: "5px 15px 5px 15px",
                      display: "flex",
                      alignItems: "flex-end",
                      color: "black",
                      backgroundColor: "#F2F8FD ",
                      fontSize: "14px",
                      lineHeight: "19px",
                      cursor: "pointer",
                    }}
                  >
               {containerId == "6" ? questionDetail?.time_taken_for_answer : time}
                  </Box>
                </>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display:
                (containerId === "6" || containerId === "10") &&
                isAllQuestionAttempt === false
                  ? "flex"
                  : "none",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Box>
                <Box
                  sx={{
                    fontSize: { xs: "24px", md: "18px" },
                    fontWeight: { xs: "700", md: "600" },
                    color: color,
                  }}
                >
                  {" "}
                  {containerId == "6" || containerId == "10" ? (
                    container_data?.display_name
                  ) : (
                    <FormattedMessage id="examines-analogies" />
                  )}
                  {containerId == "10" && isAllQuestionAttempt == false && (
                    <>({student_level})</>
                  )}
                </Box>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: color,
                    mt: "5%",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {" "}
                  <FormattedMessage id="exam-time:" />
                  {container_data?.total_examtime}{" "}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: color,
                }}
              >
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />{" "}
                {containerId === "6" && (
                  <>
                    ({score} <FormattedMessage id="points" />)
                  </>
                )}{" "}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: color,
                  // mt: "5%",
                }}
              >
                <span>
                  {" "}
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: color,
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    {" "}
                    <FormattedMessage id="exam-time:" />
                    {container_data?.total_examtime}{" "}
                  </Box>
                </span>
                <FormattedMessage id="have-passed" />

                <Box
                  sx={{
                    width: "70px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {showTime ? (
                    <Box
                      onClick={handleTimeClick}
                      sx={{
                        border: "1px solid #3397DB",
                        fontWeight: "600",
                        borderRadius: "20px",
                        padding: "5px 15px 5px 15px",
                        display: "flex",
                        alignItems: "flex-end",
                        color: "black",
                        backgroundColor: "#F2F8FD ",
                        fontSize: "14px",
                        lineHeight: "19px",
                        cursor: "pointer",
                      }}
                    >
                      {time}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 0,
                      }}
                    >
                      {renderImg === true ? (
                        <img
                          className="library-item-img"
                          src={TimerIcon}
                          alt="TimerIcon"
                          style={{
                            cursor: "pointer",

                            height: "20px",
                            width: "20px",
                          }}
                          onClick={handleTimerIconClick}
                        />
                      ) : (
                        <WhiteTimerIcon
                          onClick={handleTimerIconClick}
                          style={{
                            cursor: "pointer",

                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: "724px",
            height: "auto",
            backgroundColor:
              containerId === "7" || containerId === "8"
                ? "#ffffff"
                : isAllQuestionAttempt !== true
                ? "#ffffff"
                : "transparent",
            flexDirection: "column",
            borderRadius: { xs: "5px", md: "20px" },
            padding: { xs: "5px", md: "25px" },
            margin: "0 auto",
            position: "relative",
            marginTop: {
              xs: containerId === "1" ? "30px" : "20px",
              md: "20px",
            },
          }}
          ref={questionRef}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {!isMediumScreen || containerId === "1" ? (
              <>
                {isAllQuestionAttempt ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        width: "fit-content",
                        marginTop: "-10px",
                      }}
                      onClick={(x) => setShowQuestion((x) => !x)}
                    >
                      <Typography
                        sx={{
                          color: "var(--black-black-80, #4A5056)",
                          textAlign: "right",
                          fontFamily: "Noto Sans Hebrew",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        {showQuestion ? (
                          <>
                            <FormattedMessage id="hide-question" />
                          </>
                        ) : (
                          <>
                            <FormattedMessage id="show-question" />
                          </>
                        )}{" "}
                      </Typography>

                      <Box
                        sx={{
                          transform: showQuestion
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          marginTop: showQuestion ? "-5px" : "5px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#7A49B9"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {" "}
                <Typography variant="body1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question,
                    }}
                  />
                </Typography>
              </>
            )}

            {isAllQuestionAttempt || isMediumScreen ? (
              <Typography
                variant="body1"
                sx={{ display: showQuestion ? "flex" : "none" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: question,
                  }}
                />
              </Typography>
            ) : (
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: question,
                  }}
                />
              </Typography>
            )}

            <TextField
              style={{ width: "100%" }}
              variant="standard"
              onChange={handleChange}
            />
            {showResultMode || isAllQuestionAttempt ? (
              <>
                <DescriptionBox
                  description={solving_explanation}
                  file_path={explanation_image?.file_path}
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#4A5056",
                      marginTop: "-8px",
                    }}
                  >
                    ID:
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "14px",
                        color: "#4A5056",
                      }}
                    >
                      {question_id}
                    </span>
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
            {isAllQuestionAttempt === true ? (
              <></>
            ) : (
              <Button
                onClick={() =>
                  onSubmit({
                    user_id: user_id,
                    container_id: searchParams.get("container_id"),
                    question_id: question_id,
                    answer: openTextBoxAnswer,
                    time_taken_for_answer: differenceValue,
                  })
                }
                sx={{
                  m: "auto",
                  display: {
                    xs:
                      containerId === "7" || containerId === "8"
                        ? "none"
                        : "block",
                    md:
                      containerId === "7" || containerId === "8"
                        ? "block"
                        : "block",
                  },
                }}
                variant="contained"
              >
                <FormattedMessage id="next-question" />
              </Button>
            )}
          </Box>
        </Box>
        {/* {questions[activeQuestion]?.solving_explanation !== null &&
          questions[activeQuestion]?.solving_explanation !== "" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                maxWidth: "724px",
                margin: "0 auto",
              }}
            >
              <Typography sx={{ fontSize: "14px", color: textColor }}>
                ID:
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "14px",
                    color: textColor,
                  }}
                >
                  {question_id}
                </span>
              </Typography>
            </Box>
          )} */}
      </Grid>

      {(containerId === "7" || containerId === "8") && (
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            width: ["-webkit-fill-available", "-moz-available"],
            paddingBottom: "0px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            background: "var(--White, #FFF)",
            height: "65px",
            position: "fixed",
            bottom: "0px",
          }}
        >
          {isAllQuestionAttempt === true ? (
            <></>
          ) : (
            <Button
              onClick={() =>
                onSubmit({
                  user_id: user_id,
                  container_id: searchParams.get("container_type_id"),
                  question_id: question_id,
                  answer: openTextBoxAnswer,
                  time_taken_for_answer: differenceValue,
                })
              }
              sx={{
                m: "auto",
                display: {
                  xs:
                    containerId === "7" || containerId === "8"
                      ? "block"
                      : "none",
                  md: "none",
                },
              }}
              variant="contained"
            >
              <FormattedMessage
                id={currentQuestion == lastIndex + 1 ? "done" : "next-question"}
              />{" "}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default OpenTextBox;
