import { Outlet } from "react-router-dom";
import ImageTemplate from "../views/common/ImageTemplate";
import LandingScreenImg from "../assets/images/landing-screen.png";
import VerifyUserId from "../views/auth/VerifyUserId";
import Login from "../views/auth/Login";
import VerifyOTP from "../views/auth/VerifyOTP";
import CreatePassword from "../views/auth/CreatePassword";
import ForgotPassword from "../views/auth/ForgotPassword";

const LoginRoutes = {
  path: "/",
  element: (
    <>
      <Outlet />
    </>
  ),
  children: [
    {
      path: "/",
      element: <ImageTemplate component={<Login />} image={LandingScreenImg} />,
    },
    {
      path: "/verifyotp",
      element: (
        <ImageTemplate component={<VerifyOTP />} image={LandingScreenImg} />
      ),
    },
    {
      path: "/first-login",
      element: (
        <ImageTemplate component={<VerifyUserId />} image={LandingScreenImg} />
      ),
    },
    {
      path: "/create-password",
      element: (
        <ImageTemplate
          component={<CreatePassword />}
          image={LandingScreenImg}
        />
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <ImageTemplate
          component={<ForgotPassword />}
          image={LandingScreenImg}
        />
      ),
    },
  ],
};

export default LoginRoutes;
