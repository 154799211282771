import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface excelLessonsLearnedList {
  student_id: string | null;
}
interface readLesson {
  student_id: string | null;
  question_id: string;
  container_id: string;
}
interface topicList {
  student_id: string | null;
  course_id: string;
}
interface getPermission {
  course_id: string | undefined;
}
interface addLessonsLearned {
  student_id: string | null;
  topic_id: string;
  notes: string;
  where_question_come_from: string;
  question_number: string;
  container_id: string;
  course_id: string;
}
interface pin_unpin_questionAnswer {
  student_id: string | null;
  notes_id: string;
  course_id: string;
}
interface lessonsLearnedList {
  student_id: string | null | undefined;
  course_id: string;
  per_page: number;
  page: number;
  where_question_come_from?: string;
  topic_id?: string;
}
interface Child {
  id: number;
  checked: boolean;
  subHeader: string;
}

interface Parent {
  id: number;
  header: string;
  checked: boolean;
  children: Child[];
}

export const LessonsLearnedList = createAsyncThunk(
  "LessonsSlice/lessonsLearnedList",
  async (data: lessonsLearnedList) => {
    const {
      student_id,
      per_page,
      page,
      where_question_come_from,
      topic_id,
      course_id,
    } = data;
    const url =
      `lessons-learned-list?student_id=${student_id}&course_id=${course_id}&per_page=${per_page}&page=${page}` +
      `${
        where_question_come_from
          ? `&where_question_come_from=${where_question_come_from}`
          : ""
      }` +
      `${topic_id ? `&topic_id=${topic_id}` : ""}`;
    try {
      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const ExcelLessonsLearnedList = createAsyncThunk(
  "LessonsSlice/excelLessonsLearnedList",
  async (data: excelLessonsLearnedList) => {
    try {
      const res = await axiosClient.get(
        `export-lessons-learned-list?student_id=${data.student_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const ReadLesson = createAsyncThunk(
  "LessonsSlice/readLesson",
  async (data: readLesson) => {
    try {
      const res = await axiosClient.get(
        `get-lesson?student_id=${data.student_id}&question_id=${data.question_id}&container_id=${data.container_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetPermission = createAsyncThunk(
  "LessonsSlice/getPermission",
  async (data: getPermission) => {
    try {
      const res = await axiosClient.get(
        `get-menu-permission?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const TopicList = createAsyncThunk(
  "LessonsSlice/topicList",
  async (data: topicList) => {
    try {
      const res = await axiosClient.get(
        `topic-list?student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const AddLessonsLearned = createAsyncThunk(
  "LessonsSlice/addLessonsLearned",
  async (data: addLessonsLearned) => {
    try {
      const res = await axiosClient.post("add-lessons-learned", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const PinQuestionAnswer = createAsyncThunk(
  "LessonsSlice/pinQuestionAnswer",
  async (data: pin_unpin_questionAnswer) => {
    try {
      const res = await axiosClient.post("pin-question-answer", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const UnPinQuestionAnswer = createAsyncThunk(
  "LessonsSlice/unPinQuestionAnswer",
  async (data: pin_unpin_questionAnswer) => {
    try {
      const res = await axiosClient.post("unpin-question-answer", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  isLoading: boolean;
  lessonBookList: any[];
  excelData: any[];
  permission:Parent[];
  isAddLessonOpen: boolean;
  user_name: string;
  topicLists: any[];
  topicListsQuestionFrom: any[];
  addLessonsLearned: any[];
  total: number;
}

const initialState: initialStage = {
  isLoading: false,
  lessonBookList: [],
  excelData: [],
  permission:[],
  user_name: "",
  isAddLessonOpen: false,
  topicLists: [],
  addLessonsLearned: [],
  topicListsQuestionFrom: [],
  total: 0,
};

const LessonsBook = createSlice({
  name: "lessonsBooks",
  initialState,
  reducers: {
    setAddLessonOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddLessonOpen = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(LessonsLearnedList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(LessonsLearnedList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          lessonBookList: action.payload.data.data,
          total: action.payload.data.total,
        };
      })
      .addCase(LessonsLearnedList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(ExcelLessonsLearnedList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(ExcelLessonsLearnedList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          excelData: action.payload.data,
          user_name: action.payload.user_name,
        };
      })
      .addCase(ExcelLessonsLearnedList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(ReadLesson.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(ReadLesson.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          lessonBookList: action.payload.data,
        };
      })
      .addCase(ReadLesson.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(GetPermission.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetPermission.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          permission: action.payload.data,
        };
      })
      .addCase(GetPermission.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(TopicList.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(TopicList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          topicLists: action.payload.data,
          topicListsQuestionFrom: action.payload.where_question_come_from,
        };
      })
      .addCase(TopicList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(AddLessonsLearned.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(AddLessonsLearned.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          addLessonsLearned: action.payload.data,
        };
      })
      .addCase(AddLessonsLearned.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(PinQuestionAnswer.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(PinQuestionAnswer.fulfilled, (state, { payload }) => {
        const pinArray = [...current(state.lessonBookList)];
        let findindex = pinArray.findIndex(
          (item) => item.id === payload.data.id
        );
        if (findindex > -1) {
          pinArray[findindex] = {
            ...pinArray[findindex], // Copy existing properties
            is_pinned: payload.data.is_pinned, // Modify is_pinned property
          };
        }
        state.lessonBookList = pinArray;
      })
      .addCase(PinQuestionAnswer.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(UnPinQuestionAnswer.fulfilled, (state, { payload }) => {
        const unPinArray = [...current(state.lessonBookList)];
        let findindex = unPinArray.findIndex(
          (item) => item.id === payload.data.id
        );
        if (findindex > -1) {
          unPinArray[findindex] = {
            ...unPinArray[findindex], // Copy existing properties
            is_pinned: payload.data.is_pinned, // Modify is_pinned property
          };
        }
        state.lessonBookList = unPinArray;
      })
      .addCase(UnPinQuestionAnswer.rejected, (state, action) => {
        return {
          ...state,
        };
      });
  },
});

export const { setAddLessonOpen } = LessonsBook.actions;
export default LessonsBook.reducer;
