import * as React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import ProgressBar from "./ProgressBar/ProgressBar";
import QuizType from "./ContainerType/QuizType";
import SeriesOfQuestionType from "./ContainerType/SeriesOfQuestionType";
import SingleQuestionType from "./ContainerType/SingleQuestionType";
import Sidebar from "./Sidebar/Sidebar";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "Redux/App/hooks";
import SplitScreen from "./ContainerType/SplitScreen";
import { Box, Typography, IconButton, Modal } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AtomicTypee from "views/QuestionPage/AtomicTyepe/index";
import BookAssignment from "./ContainerType/BookAssignment";
import ChapterExam from "./ContainerType/ChapterExam";
import kidumLogo from "../../assets/images/kidum-logo.jpg";
import { useWiris } from "hooks/useWiris";
import NewPracticeSLQuestionPage from "views/student-library/StudentLibrarySubPage/NewPracticeSLQustionsPage";
import TheOvenSLQuestionPage from "views/student-library/StudentLibrarySubPage/TheOvenSLQustionPage";
import OnlineExam from "./ContainerType/OnlineExam";
interface MyComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      width: "100%",
      minHeight: "100vh",
      height: "auto",
      display: "flex",
      flexDirection: "row-reverse",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100%)",
    },
    progressBarContainer: {
      height: "70px",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contentContainer: {
      justifyContent: "center",
      alignItems: "flex-start",
      height: "calc(100% - 70px)",
      width: "100%",
    },
    sidebarContainer: {
      position: "relative",
    },
  })
);
const QuestionPages: React.FC<MyComponentProps> = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const containerId = searchParams.get("container_type_id");
  const atomicType = searchParams.get("atomic_type");
  const atomicSideBar: any = searchParams.get("atomicSideBar");
  const lesson = searchParams.get("lesson");

  const isAllQuestionAttempt = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const setFlagProgressBarNotes = useAppSelector(
    (state) => state.Questions.setFlagProgressBarNotes
  );
  const setNumericBar: boolean = useAppSelector(
    (state) => state.Questions.numericBar
  );

  const renderPreview: boolean = useAppSelector(
    (state) => state.Questions.renderPreview
  );
  const previewName = useAppSelector((state) => state.Questions.previewName);
  const resetAss = useAppSelector((state) => state.Questions.resetAss);

  const isLoading: boolean = useAppSelector(
    (state) => state.Questions.isLoading
  );

  const researchTab = useAppSelector((state) => state.Questions.researchTab);

  useWiris(isLoading);
  useWiris(!isAllQuestionAttempt);

  const question_id = searchParams.get("question_id");
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const handleDialogueOpen = () => setOpenDialogue(true);
  const handleDialogueClose = () => setOpenDialogue(false);
  const scoreModalView = {
    position: "fixed",
    top: "85px",
    right: "0",
    width: "325px",
    maxWidth: "calc(100% - 30px)",
    background: "#FFF",
    borderRadius: "20px 0 0 20px",
  };

  return (
    <div className={classes.mainDiv}>
      <div className={classes.container}>
        {(searchParams.get("atomic_type") && searchParams.get("atomic_id")) ||
        (searchParams.get("container_type_id") === "4" &&
          setNumericBar === false) ||
        (searchParams.get("container_type_id") === "10" && researchTab) ||
        (searchParams.get("container_type_id") === "5" &&
          setNumericBar === false) ? (
          <></>
        ) : (
          <>
            {(!question_id || resetAss) && (
              <div
                className={classes.progressBarContainer}
                style={
                  atomicSideBar === false || atomicSideBar === null
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <ProgressBar containerType={containerId} />
              </div>
            )}
          </>
        )}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-start",
            width: "100%",
            height: atomicType ? "100%" : "auto",
          }}
        >
          <Box sx={{ padding: { xs: "0 7px", md: "7px" } }}>
            <img
              className="header-logo"
              style={{
                position:
                  (containerId === "5" || containerId === "4") &&
                  setNumericBar === false
                    ? "relative"
                    : "absolute",
                top:
                  (containerId === "5" || containerId === "4") &&
                  setNumericBar === false
                    ? "15px"
                    : lesson
                    ? "6px"
                    : "26px",
                right: "8px",
              }}
              src={kidumLogo}
              alt="kidum"
            />
          </Box>
        </Box>

        <Box
          className={classes.contentContainer}
          id="atomicWrap"
          sx={{
            backgroundColor:
              isAllQuestionAttempt === false &&
              (containerId === "6" || (containerId === "10" && !researchTab))
                ? "#7A49B9"
                : "#F8F9FA",
            height: {
              xs:
                isAllQuestionAttempt === false &&
                searchParams.get("container_type_id") === "5"
                  ? "calc(100% - 48px)"
                  : "calc(100% - 70px)",
              md: "calc(100% - 70px)",
            },
          }}
        >
          {searchParams.get("container_type_id") === "6" && <QuizType />}
          {searchParams.get("container_type_id") === "10" && <OnlineExam />}
          {searchParams.get("container_type_id") === "2" && (
            <SeriesOfQuestionType />
          )}
          {searchParams.get("container_type_id") === "1" && (
            <SingleQuestionType />
          )}
          {searchParams.get("container_type_id") === "3" && <SplitScreen />}
          {searchParams.get("container_type_id") === "4" && <BookAssignment />}
          {searchParams.get("container_type_id") === "5" && <ChapterExam />}
          {searchParams.get("container_type_id") === "7" && (
            <NewPracticeSLQuestionPage />
          )}
          {searchParams.get("container_type_id") === "8" && (
            <TheOvenSLQuestionPage />
          )}
          {searchParams.get("atomic_type") !== null && <AtomicTypee />}
        </Box>
      </div>
      {!renderPreview &&
      (setFlagProgressBarNotes === true ||
        containerId === "1" ||
        containerId === "2" ||
        containerId === "3" ||
        containerId === "4" ||
        containerId === "5" ||
        containerId === "7" ||
        containerId === "8" ||
        atomicType !== null) ? (
        <>
          <Box
            display={{
              xs:
                containerId === "7" || containerId === "8"
                  ? "flex"
                  : atomicSideBar === false || atomicSideBar === null
                  ? "flex"
                  : "none",
              md:
                atomicSideBar === false || atomicSideBar === null
                  ? "flex"
                  : "none",
            }}
          >
            <Box
              onClick={handleDialogueOpen}
              className={`${
                activeQuestion == 0
                  ? "question-option-5 question-option-mobile-5"
                  : ""
              }`}
              sx={{
                display: { xs: "flex", md: "flex" },
                position: "absolute",
                marginTop:
                  containerId == "7" && isAllQuestionAttempt ? "45px" : "0px",
                backgroundColor: "#FFF",
                borderRadius:
                  searchParams.get("atomic_type") !== null
                    ? "none"
                    : "30px 0 0 30px",
                pl: searchParams.get("atomic_type") !== null ? "0px" : "10px",
                boxShadow:
                  searchParams.get("atomic_type") !== null
                    ? "none"
                    : "0 2px 9px rgba(0,0,0,0.09)",
                borderTop:
                  searchParams.get("atomic_type") == null
                    ? "0px"
                    : "1px solid #DFE2E5",
                top:
                  containerId === "5" && setNumericBar === false
                    ? "75px"
                    : containerId === "5" && setNumericBar === true
                    ? "92px"
                    : containerId === "4" && setNumericBar === false
                    ? "105px"
                    : containerId === "10" && setNumericBar === false
                    ? "59px"
                    : "85px",
                right: "0",
                "@media (max-width: 767px)": {
                  width: searchParams.get("atomic_type") && "100%",
                  top: searchParams.get("atomic_type") && "70px",
                },
                width:
                  containerId === "5"
                    ? "35px"
                    : containerId === "10" || containerId === "1"
                    ? "28px"
                    : "132px",
                alignItems: "center",
                flexDirection: "row-reverse",
              }}
            >
              <Typography
                variant="body2"
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                marginRight={1}
                marginLeft={containerId == "7" && isAllQuestionAttempt ? 0 : 1}
                paddingLeft={searchParams.get("atomic_type") !== null ? 32 : 0}
                display={
                  containerId === "5" ||
                  containerId === "10" ||
                  containerId === "1"
                    ? "none"
                    : "block"
                }
              >
                {previewName ? (
                  previewName
                ) : containerId == "7" || containerId == "8" ? (
                  <FormattedMessage id="online-practice" />
                ) : (
                  <FormattedMessage id="task-name" />
                )}
              </Typography>
              <IconButton
                size="large"
                aria-label="progressbar toggle"
                aria-controls="menu-appBar"
                aria-haspopup="true"
                color="inherit"
                sx={{ padding: "6px 0px", width: "36px" }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 6H3"
                    stroke="#222529"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12H3"
                    stroke="#222529"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 18H3"
                    stroke="#222529"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 6H20.99"
                    stroke="#222529"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 12H20.99"
                    stroke="#222529"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 18H20.99"
                    stroke="#222529"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </IconButton>
            </Box>
            {!question_id && (
              <Box
                className={classes.sidebarContainer}
                sx={{
                  display: { xs: "none", md: "flex" },
                  backgroundColor: "#fff",
                  width: {
                    xs: "280px",
                    md: containerId == "7" ? "206px" : "176px",
                  },
                  height: { xs: "100%", md: "100%" },
                  position: { xs: "fixed", md: "relative" },
                  top: { xs: "70px", md: "auto" },
                  right: { xs: "0", md: "auto" },
                  maxHeight: { xs: "500px", sm: "100%" },
                  overflow: "auto",
                  direction: { xs: "ltr", md: "rtl" },
                  borderRadius: { xs: "15px 0 0 15px", md: "0" },
                }}
              >
                <Sidebar currentStep={4} />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <></>
      )}
      <div>
        <Modal
          open={openDialogue}
          onClose={handleDialogueClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <Box sx={scoreModalView}>
              <Box
                onClick={handleDialogueClose}
                sx={{
                  display: { xs: "flex" },
                  cursor: "pointer",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
              >
                <Box sx={{ display: { xs: "flex" }, pl: "15px" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19"
                      stroke="#4A5056"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5L19 12L12 19"
                      stroke="#4A5056"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex" },
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Typography variant="body2" marginRight={1}>
                    {previewName ? (
                      <Typography
                        variant="body2"
                        marginRight={1}
                        marginLeft={1}
                        sx={{
                          maxWidth: "120px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {previewName}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        marginRight={1}
                        marginLeft={1}
                        sx={{
                          maxWidth: "120px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {containerId == "7" || containerId == "8" ? (
                          <FormattedMessage id="online-practice" />
                        ) : (
                          <FormattedMessage id="task-name" />
                        )}{" "}
                      </Typography>
                    )}
                  </Typography>
                  <IconButton
                    size="large"
                    aria-label="progressbar toggle"
                    aria-controls="menu-appBar"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 6H3"
                        stroke="#222529"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 12H3"
                        stroke="#222529"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 18H3"
                        stroke="#222529"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 6H20.99"
                        stroke="#222529"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 12H20.99"
                        stroke="#222529"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 18H20.99"
                        stroke="#222529"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </IconButton>
                </Box>
              </Box>
              <Box
                className="mobile-sidebar-menulist"
                sx={{ height: "400px", overflow: "auto" }}
              >
                <Sidebar currentStep={4} />
              </Box>
            </Box>
          </>
        </Modal>
      </div>
    </div>
  );
};

export default QuestionPages;
